/* eslint-disable */

import * as olxmlModule from 'ol/xml';
import * as olwebglModule from 'ol/webgl';
import * as olutilModule from 'ol/util';
import * as oltransformModule from 'ol/transform';
import * as oltilegridWMTSModule from 'ol/tilegrid/WMTS';
import * as oltilegridModule from 'ol/tilegrid';
import * as olstyleIconImageCacheModule from 'ol/style/IconImageCache';
import * as olsphereModule from 'ol/sphere';
import * as olsourcewmsModule from 'ol/source/wms';
import * as olsourcestaticModule from 'ol/source/static';
import * as olsourcemapguideModule from 'ol/source/mapguide';
import * as olsourcearcgisRestModule from 'ol/source/arcgisRest';
import * as olsourceWMTSModule from 'ol/source/WMTS';
import * as olsourceVectorModule from 'ol/source/Vector';
import * as olsourceTileModule from 'ol/source/Tile';
import * as olsourceRasterModule from 'ol/source/Raster';
import * as olsourceOSMModule from 'ol/source/OSM';
import * as olsourceModule from 'ol/source';
import * as olsourceImageModule from 'ol/source/Image';
import * as olsizeModule from 'ol/size';
import * as olrenderModule from 'ol/render';
import * as olrenderFeatureModule from 'ol/render/Feature';
import * as olprojproj4Module from 'ol/proj/proj4';
import * as olprojUnitsModule from 'ol/proj/Units';
import * as olprojModule from 'ol/proj';
import * as olloadingstrategyModule from 'ol/loadingstrategy';
import * as ollayerGroupModule from 'ol/layer/Group';
import * as olinteractiondefaultsModule from 'ol/interaction/defaults';
import * as olinteractionTranslateModule from 'ol/interaction/Translate';
import * as olinteractionSelectModule from 'ol/interaction/Select';
import * as olinteractionModifyModule from 'ol/interaction/Modify';
import * as olinteractionExtentModule from 'ol/interaction/Extent';
import * as olinteractionDrawModule from 'ol/interaction/Draw';
import * as olinteractionDragBoxModule from 'ol/interaction/DragBox';
import * as olinteractionDragAndDropModule from 'ol/interaction/DragAndDrop';
import * as olhasModule from 'ol/has';
import * as olgeomPolygonModule from 'ol/geom/Polygon';
import * as olformatfilterModule from 'ol/format/filter';
import * as olformatWFSModule from 'ol/format/WFS';
import * as olformatPolylineModule from 'ol/format/Polyline';
import * as olfeatureloaderModule from 'ol/featureloader';
import * as olextentModule from 'ol/extent';
import * as oleventsconditionModule from 'ol/events/condition';
import * as oleventsSnapEventModule from 'ol/events/SnapEvent';
import * as oleasingModule from 'ol/easing';
import * as olcoordinateModule from 'ol/coordinate';
import * as olcontroldefaultsModule from 'ol/control/defaults';
import * as olcolorlikeModule from 'ol/colorlike';
import * as olcolorModule from 'ol/color';
import * as olarrayModule from 'ol/array';
import * as olObservableModule from 'ol/Observable';
import * as olObjectModule from 'ol/Object';
import * as olImageModule from 'ol/Image';
import * as olGeolocationModule from 'ol/Geolocation';
import * as olCollectionModule from 'ol/Collection';
import $ol$tilegrid$WMTS from 'ol/tilegrid/WMTS';
import $ol$tilegrid$TileGrid from 'ol/tilegrid/TileGrid';
import $ol$style$Text from 'ol/style/Text';
import $ol$style$Style from 'ol/style/Style';
import $ol$style$Stroke from 'ol/style/Stroke';
import $ol$style$RegularShape from 'ol/style/RegularShape';
import $ol$style$Image from 'ol/style/Image';
import $ol$style$IconImageCache from 'ol/style/IconImageCache';
import $ol$style$Icon from 'ol/style/Icon';
import $ol$style$Fill from 'ol/style/Fill';
import $ol$style$Circle from 'ol/style/Circle';
import $ol$structs$LRUCache from 'ol/structs/LRUCache';
import $ol$source$Zoomify from 'ol/source/Zoomify';
import $ol$source$XYZ from 'ol/source/XYZ';
import $ol$source$WMTS from 'ol/source/WMTS';
import $ol$source$VectorTile from 'ol/source/VectorTile';
import $ol$source$Vector from 'ol/source/Vector';
import $ol$source$UrlTile from 'ol/source/UrlTile';
import $ol$source$UTFGrid from 'ol/source/UTFGrid';
import $ol$source$TileWMS from 'ol/source/TileWMS';
import $ol$source$TileJSON from 'ol/source/TileJSON';
import $ol$source$TileImage from 'ol/source/TileImage';
import $ol$source$TileDebug from 'ol/source/TileDebug';
import $ol$source$TileArcGISRest from 'ol/source/TileArcGISRest';
import $ol$source$Tile from 'ol/source/Tile';
import $ol$source$StadiaMaps from 'ol/source/StadiaMaps';
import $ol$source$Source from 'ol/source/Source';
import $ol$source$Raster from 'ol/source/Raster';
import $ol$source$OSM from 'ol/source/OSM';
import $ol$source$OGCVectorTile from 'ol/source/OGCVectorTile';
import $ol$source$OGCMapTile from 'ol/source/OGCMapTile';
import $ol$source$ImageWMS from 'ol/source/ImageWMS';
import $ol$source$ImageStatic from 'ol/source/ImageStatic';
import $ol$source$ImageMapGuide from 'ol/source/ImageMapGuide';
import $ol$source$ImageCanvas from 'ol/source/ImageCanvas';
import $ol$source$ImageArcGISRest from 'ol/source/ImageArcGISRest';
import $ol$source$Image from 'ol/source/Image';
import $ol$source$IIIF from 'ol/source/IIIF';
import $ol$source$GeoTIFF from 'ol/source/GeoTIFF';
import $ol$source$DataTile from 'ol/source/DataTile';
import $ol$source$Cluster from 'ol/source/Cluster';
import $ol$source$CartoDB from 'ol/source/CartoDB';
import $ol$source$BingMaps from 'ol/source/BingMaps';
import $ol$renderer$webgl$TileLayer from 'ol/renderer/webgl/TileLayer';
import $ol$renderer$webgl$PointsLayer from 'ol/renderer/webgl/PointsLayer';
import $ol$renderer$canvas$VectorTileLayer from 'ol/renderer/canvas/VectorTileLayer';
import $ol$renderer$canvas$VectorLayer from 'ol/renderer/canvas/VectorLayer';
import $ol$renderer$canvas$VectorImageLayer from 'ol/renderer/canvas/VectorImageLayer';
import $ol$renderer$canvas$TileLayer from 'ol/renderer/canvas/TileLayer';
import $ol$renderer$canvas$ImageLayer from 'ol/renderer/canvas/ImageLayer';
import $ol$renderer$Composite from 'ol/renderer/Composite';
import $ol$render$canvas$Immediate from 'ol/render/canvas/Immediate';
import $ol$render$VectorContext from 'ol/render/VectorContext';
import $ol$render$Feature from 'ol/render/Feature';
import $ol$render$Event from 'ol/render/Event';
import $ol$proj$Projection from 'ol/proj/Projection';
import $ol$layer$WebGLTile from 'ol/layer/WebGLTile';
import $ol$layer$VectorTile from 'ol/layer/VectorTile';
import $ol$layer$VectorImage from 'ol/layer/VectorImage';
import $ol$layer$Vector from 'ol/layer/Vector';
import $ol$layer$Tile from 'ol/layer/Tile';
import $ol$layer$Layer from 'ol/layer/Layer';
import $ol$layer$Image from 'ol/layer/Image';
import $ol$layer$Heatmap from 'ol/layer/Heatmap';
import $ol$layer$Group from 'ol/layer/Group';
import $ol$layer$Graticule from 'ol/layer/Graticule';
import $ol$layer$BaseVector from 'ol/layer/BaseVector';
import $ol$layer$BaseTile from 'ol/layer/BaseTile';
import $ol$layer$BaseImage from 'ol/layer/BaseImage';
import $ol$layer$Base from 'ol/layer/Base';
import $ol$interaction$Translate from 'ol/interaction/Translate';
import $ol$interaction$Snap from 'ol/interaction/Snap';
import $ol$interaction$Select from 'ol/interaction/Select';
import $ol$interaction$Pointer from 'ol/interaction/Pointer';
import $ol$interaction$PinchZoom from 'ol/interaction/PinchZoom';
import $ol$interaction$PinchRotate from 'ol/interaction/PinchRotate';
import $ol$interaction$MouseWheelZoom from 'ol/interaction/MouseWheelZoom';
import $ol$interaction$Modify from 'ol/interaction/Modify';
import $ol$interaction$Link from 'ol/interaction/Link';
import $ol$interaction$KeyboardZoom from 'ol/interaction/KeyboardZoom';
import $ol$interaction$KeyboardPan from 'ol/interaction/KeyboardPan';
import $ol$interaction$Interaction from 'ol/interaction/Interaction';
import $ol$interaction$Extent from 'ol/interaction/Extent';
import $ol$interaction$Draw from 'ol/interaction/Draw';
import $ol$interaction$DragZoom from 'ol/interaction/DragZoom';
import $ol$interaction$DragRotateAndZoom from 'ol/interaction/DragRotateAndZoom';
import $ol$interaction$DragRotate from 'ol/interaction/DragRotate';
import $ol$interaction$DragPan from 'ol/interaction/DragPan';
import $ol$interaction$DragBox from 'ol/interaction/DragBox';
import $ol$interaction$DragAndDrop from 'ol/interaction/DragAndDrop';
import $ol$interaction$DoubleClickZoom from 'ol/interaction/DoubleClickZoom';
import $ol$interaction$DblClickDragZoom from 'ol/interaction/DblClickDragZoom';
import $ol$geom$SimpleGeometry from 'ol/geom/SimpleGeometry';
import $ol$geom$Polygon from 'ol/geom/Polygon';
import $ol$geom$Point from 'ol/geom/Point';
import $ol$geom$MultiPolygon from 'ol/geom/MultiPolygon';
import $ol$geom$MultiPoint from 'ol/geom/MultiPoint';
import $ol$geom$MultiLineString from 'ol/geom/MultiLineString';
import $ol$geom$LinearRing from 'ol/geom/LinearRing';
import $ol$geom$LineString from 'ol/geom/LineString';
import $ol$geom$GeometryCollection from 'ol/geom/GeometryCollection';
import $ol$geom$Geometry from 'ol/geom/Geometry';
import $ol$geom$Circle from 'ol/geom/Circle';
import $ol$format$filter$Within from 'ol/format/filter/Within';
import $ol$format$filter$Or from 'ol/format/filter/Or';
import $ol$format$filter$NotEqualTo from 'ol/format/filter/NotEqualTo';
import $ol$format$filter$Not from 'ol/format/filter/Not';
import $ol$format$filter$LessThanOrEqualTo from 'ol/format/filter/LessThanOrEqualTo';
import $ol$format$filter$LessThan from 'ol/format/filter/LessThan';
import $ol$format$filter$IsNull from 'ol/format/filter/IsNull';
import $ol$format$filter$IsLike from 'ol/format/filter/IsLike';
import $ol$format$filter$IsBetween from 'ol/format/filter/IsBetween';
import $ol$format$filter$Intersects from 'ol/format/filter/Intersects';
import $ol$format$filter$GreaterThanOrEqualTo from 'ol/format/filter/GreaterThanOrEqualTo';
import $ol$format$filter$GreaterThan from 'ol/format/filter/GreaterThan';
import $ol$format$filter$EqualTo from 'ol/format/filter/EqualTo';
import $ol$format$filter$During from 'ol/format/filter/During';
import $ol$format$filter$Disjoint from 'ol/format/filter/Disjoint';
import $ol$format$filter$DWithin from 'ol/format/filter/DWithin';
import $ol$format$filter$Contains from 'ol/format/filter/Contains';
import $ol$format$filter$Bbox from 'ol/format/filter/Bbox';
import $ol$format$XMLFeature from 'ol/format/XMLFeature';
import $ol$format$XML from 'ol/format/XML';
import $ol$format$WMTSCapabilities from 'ol/format/WMTSCapabilities';
import $ol$format$WMSGetFeatureInfo from 'ol/format/WMSGetFeatureInfo';
import $ol$format$WMSCapabilities from 'ol/format/WMSCapabilities';
import $ol$format$WKT from 'ol/format/WKT';
import $ol$format$WKB from 'ol/format/WKB';
import $ol$format$WFS from 'ol/format/WFS';
import $ol$format$TopoJSON from 'ol/format/TopoJSON';
import $ol$format$TextFeature from 'ol/format/TextFeature';
import $ol$format$Polyline from 'ol/format/Polyline';
import $ol$format$OSMXML from 'ol/format/OSMXML';
import $ol$format$MVT from 'ol/format/MVT';
import $ol$format$KML from 'ol/format/KML';
import $ol$format$JSONFeature from 'ol/format/JSONFeature';
import $ol$format$IIIFInfo from 'ol/format/IIIFInfo';
import $ol$format$IGC from 'ol/format/IGC';
import $ol$format$GeoJSON from 'ol/format/GeoJSON';
import $ol$format$GPX from 'ol/format/GPX';
import $ol$format$GMLBase from 'ol/format/GMLBase';
import $ol$format$GML32 from 'ol/format/GML32';
import $ol$format$GML3 from 'ol/format/GML3';
import $ol$format$GML2 from 'ol/format/GML2';
import $ol$format$GML from 'ol/format/GML';
import $ol$format$Feature from 'ol/format/Feature';
import $ol$format$EsriJSON from 'ol/format/EsriJSON';
import $ol$events$Target from 'ol/events/Target';
import $ol$events$Event from 'ol/events/Event';
import $ol$control$ZoomToExtent from 'ol/control/ZoomToExtent';
import $ol$control$ZoomSlider from 'ol/control/ZoomSlider';
import $ol$control$Zoom from 'ol/control/Zoom';
import $ol$control$ScaleLine from 'ol/control/ScaleLine';
import $ol$control$Rotate from 'ol/control/Rotate';
import $ol$control$OverviewMap from 'ol/control/OverviewMap';
import $ol$control$MousePosition from 'ol/control/MousePosition';
import $ol$control$FullScreen from 'ol/control/FullScreen';
import $ol$control$Control from 'ol/control/Control';
import $ol$control$Attribution from 'ol/control/Attribution';
import $ol$View from 'ol/View';
import $ol$VectorTile from 'ol/VectorTile';
import $ol$Tile from 'ol/Tile';
import $ol$Overlay from 'ol/Overlay';
import $ol$Observable from 'ol/Observable';
import $ol$Object from 'ol/Object';
import $ol$MapEvent from 'ol/MapEvent';
import $ol$MapBrowserEvent from 'ol/MapBrowserEvent';
import $ol$Map from 'ol/Map';
import $ol$Kinetic from 'ol/Kinetic';
import $ol$ImageTile from 'ol/ImageTile';
import $ol$Geolocation from 'ol/Geolocation';
import $ol$Feature from 'ol/Feature';
import $ol$DataTile from 'ol/DataTile';
import $ol$Collection from 'ol/Collection';
import * as IDEEutilsModule from './facade/js/util/Utils';
import * as IDEEunitsModule from './facade/js/units';
import * as IDEEuipositionModule from './facade/js/ui/position';
import * as IDEEtoastModule from './facade/js/toast';
import * as IDEEtemplateModule from './facade/js/util/Template';
import * as IDEEstyletextoverflowModule from './facade/js/style/Textoverflow';
import * as IDEEstylestateModule from './facade/js/style/State';
import * as IDEEstylequantificationModule from './facade/js/style/Quantification';
import * as IDEEstylepatternModule from './facade/js/style/Pattern';
import * as IDEEstyleheightReferenceModule from './facade/js/style/HeightReference';
import * as IDEEstyleformModule from './facade/js/style/Form';
import * as IDEEstylechartModule from './facade/js/chart/types';
import * as IDEEstylebaselineModule from './facade/js/style/Baseline';
import * as IDEEstylealignModule from './facade/js/style/Align';
import * as IDEEstyleProportionalModule from './facade/js/style/Proportional';
import * as IDEEremoteModule from './facade/js/util/Remote';
import * as IDEEparameterModule from './facade/js/parameter/parameter';
import * as IDEEloadFilesModule from './facade/js/util/LoadFiles';
import * as IDEElayertypeModule from './facade/js/layer/Type';
import * as IDEElayerMVTModule from './facade/js/layer/MVT';
import * as IDEElayerMBTilesVectorModule from './facade/js/layer/MBTilesVector';
import * as IDEElanguageModule from './facade/js/i18n/language';
import * as IDEEimplutilwmtscapabilitiesModule from './impl/ol/js/util/wmtscapabilities';
import * as IDEEimplutilsModule from './impl/ol/js/util/Utils';
import * as IDEEimplstylebuilderModule from './impl/ol/js/style/builder';
import * as IDEEimplstyleChartModule from './impl/ol/js/style/Chart';
import * as IDEEimploljspatchesModule from './impl/ol/js/patches';
import * as IDEEimplhandlerFeatureModule from './impl/ol/js/handler/Feature';
import * as IDEEimplcontrolScaleModule from './impl/ol/js/control/Scale';
import * as IDEEgeomwkttypeModule from './facade/js/geom/WKT';
import * as IDEEgeomwfstypeModule from './facade/js/geom/WFS';
import * as IDEEgeomgeojsontypeModule from './facade/js/geom/GeoJSON';
import * as IDEEgeomModule from './facade/js/geom/Geom';
import * as IDEEfilterspatialModule from './facade/js/filter/Module';
import * as IDEEfilterModule from './facade/js/filter/Filter';
import * as IDEEevtModule from './facade/js/event/eventtype';
import * as IDEEdialogModule from './facade/js/dialog';
import * as IDEEParametersModule from './facade/js/parameter/Parameters';
import * as IDEEModule from './facade/js/api-idee';
import $IDEE$window from './facade/js/util/Window';
import $IDEE$utils from './facade/js/util/Utils';
import $IDEE$ui$Panel from './facade/js/ui/Panel';
import $IDEE$template from './facade/js/util/Template';
import $IDEE$style$chart$Variable from './facade/js/chart/Variable';
import $IDEE$style$Simple from './facade/js/style/Simple';
import $IDEE$style$Proportional from './facade/js/style/Proportional';
import $IDEE$style$Polygon from './facade/js/style/Polygon';
import $IDEE$style$Point from './facade/js/style/Point';
import $IDEE$style$Line from './facade/js/style/Line';
import $IDEE$style$Heatmap from './facade/js/style/Heatmap';
import $IDEE$style$Generic from './facade/js/style/Generic';
import $IDEE$style$Font from './facade/js/style/Font';
import $IDEE$style$FlowLine from './facade/js/style/FlowLine';
import $IDEE$style$Feature from './facade/js/style/Feature';
import $IDEE$style$Composite from './facade/js/style/Composite';
import $IDEE$style$Cluster from './facade/js/style/Cluster';
import $IDEE$style$Choropleth from './facade/js/style/Choropleth';
import $IDEE$style$Chart from './facade/js/style/Chart';
import $IDEE$style$Category from './facade/js/style/Category';
import $IDEE$remote$Response from './facade/js/util/Response';
import $IDEE$remote from './facade/js/util/Remote';
import $IDEE$polyfill from './facade/js/util/polyfills';
import $IDEE$parameter$xyz from './facade/js/parameter/xyz';
import $IDEE$parameter$tms from './facade/js/parameter/tms';
import $IDEE$parameter$mbtilesvector from './facade/js/parameter/mbtilesvector';
import $IDEE$parameter$mbtiles from './facade/js/parameter/mbtiles';
import $IDEE$parameter from './facade/js/parameter/parameter';
import $IDEE$layer$XYZ from './facade/js/layer/XYZ';
import $IDEE$layer$WMTS from './facade/js/layer/WMTS';
import $IDEE$layer$WMS from './facade/js/layer/WMS';
import $IDEE$layer$WFS from './facade/js/layer/WFS';
import $IDEE$layer$Vector from './facade/js/layer/Vector';
import $IDEE$layer$Tiles3D from './facade/js/layer/Tiles3D';
import $IDEE$layer$Terrain from './facade/js/layer/Terrain';
import $IDEE$layer$TMS from './facade/js/layer/TMS';
import $IDEE$layer$OSM from './facade/js/layer/OSM';
import $IDEE$layer$OGCAPIFeatures from './facade/js/layer/OGCAPIFeatures';
import $IDEE$layer$MapLibre from './facade/js/layer/MapLibre';
import $IDEE$layer$MVT from './facade/js/layer/MVT';
import $IDEE$layer$MBTilesVector from './facade/js/layer/MBTilesVector';
import $IDEE$layer$MBTiles from './facade/js/layer/MBTiles';
import $IDEE$layer$LayerGroup from './facade/js/layer/LayerGroup';
import $IDEE$layer$KML from './facade/js/layer/KML';
import $IDEE$layer$GeoTIFF from './facade/js/layer/GeoTIFF';
import $IDEE$layer$GeoJSON from './facade/js/layer/GeoJSON';
import $IDEE$layer$GenericVector from './facade/js/layer/GenericVector';
import $IDEE$layer$GenericRaster from './facade/js/layer/GenericRaster';
import $IDEE$impl$utils from './impl/ol/js/util/Utils';
import $IDEE$impl$util$wmtscapabilities from './impl/ol/js/util/wmtscapabilities';
import $IDEE$impl$util$TextPath from './impl/ol/js/util/Textpath';
import $IDEE$impl$style$Simple from './impl/ol/js/style/Simple';
import $IDEE$impl$style$Polygon from './impl/ol/js/style/Polygon';
import $IDEE$impl$style$Point from './impl/ol/js/style/Point';
import $IDEE$impl$style$Path from './impl/ol/js/style/Path';
import $IDEE$impl$style$Line from './impl/ol/js/style/Line';
import $IDEE$impl$style$Icon from './impl/ol/js/style/Icon';
import $IDEE$impl$style$Heatmap from './impl/ol/js/style/Heatmap';
import $IDEE$impl$style$Generic from './impl/ol/js/style/Generic';
import $IDEE$impl$style$FlowLine from './impl/ol/js/style/FlowLine';
import $IDEE$impl$style$Feature from './impl/ol/js/style/Feature';
import $IDEE$impl$style$Cluster from './impl/ol/js/style/Cluster';
import $IDEE$impl$style$Chart from './impl/ol/js/style/Chart';
import $IDEE$impl$style$Centroid from './impl/ol/js/style/Centroid';
import $IDEE$impl$source$TileWMS from './impl/ol/js/source/TileWMS';
import $IDEE$impl$source$ImageWMS from './impl/ol/js/source/ImageWMS';
import $IDEE$impl$service$WFS from './impl/ol/js/service/WFS';
import $IDEE$impl$service$OGCAPIFeatures from './impl/ol/js/service/OGCAPIFeatures';
import $IDEE$impl$ol$js$projections from './impl/ol/js/projections';
import $IDEE$impl$ol$js$patches from './impl/ol/js/patches';
import $IDEE$impl$ol$js$ext$cspline from './impl/ol/js/ext/cspline';
import $IDEE$impl$ol$js$ext$OLStyleFontSymbol from './impl/ol/js/ext/OLStyleFontSymbol';
import $IDEE$impl$ol$js$ext$OLStyleFlowLine from './impl/ol/js/ext/OLStyleFlowLine';
import $IDEE$impl$ol$js$ext$OLStyleFillPattern from './impl/ol/js/ext/OLStyleFillPattern';
import $IDEE$impl$ol$js$ext$GeomUtils from './impl/ol/js/ext/GeomUtils';
import $IDEE$impl$loader$WFS from './impl/ol/js/loader/WFS';
import $IDEE$impl$loader$KML from './impl/ol/js/loader/KML';
import $IDEE$impl$loader$JSONP from './impl/ol/js/loader/JSONP';
import $IDEE$impl$loadFiles from './impl/ol/js/util/LoadFiles';
import $IDEE$impl$layer$XYZ from './impl/ol/js/layer/XYZ';
import $IDEE$impl$layer$WMTS from './impl/ol/js/layer/WMTS';
import $IDEE$impl$layer$WMS from './impl/ol/js/layer/WMS';
import $IDEE$impl$layer$WFS from './impl/ol/js/layer/WFS';
import $IDEE$impl$layer$Vector from './impl/ol/js/layer/Vector';
import $IDEE$impl$layer$TMS from './impl/ol/js/layer/TMS';
import $IDEE$impl$layer$OSM from './impl/ol/js/layer/OSM';
import $IDEE$impl$layer$OGCAPIFeatures from './impl/ol/js/layer/OGCAPIFeatures';
import $IDEE$impl$layer$MapLibre from './impl/ol/js/layer/MapLibre';
import $IDEE$impl$layer$MVT from './impl/ol/js/layer/MVT';
import $IDEE$impl$layer$MBTilesVector from './impl/ol/js/layer/MBTilesVector';
import $IDEE$impl$layer$MBTiles from './impl/ol/js/layer/MBTiles';
import $IDEE$impl$layer$LayerGroup from './impl/ol/js/layer/LayerGroup';
import $IDEE$impl$layer$KML from './impl/ol/js/layer/KML';
import $IDEE$impl$layer$Heatmap from './impl/ol/js/layer/Heatmap';
import $IDEE$impl$layer$GeoTIFF from './impl/ol/js/layer/GeoTIFF';
import $IDEE$impl$layer$GeoJSON from './impl/ol/js/layer/GeoJSON';
import $IDEE$impl$layer$GenericVector from './impl/ol/js/layer/GenericVector';
import $IDEE$impl$layer$GenericRaster from './impl/ol/js/layer/GenericRaster';
import $IDEE$impl$layer$Draw from './impl/ol/js/layer/Draw';
import $IDEE$impl$layer$AnimatedCluster from './impl/ol/js/layer/AnimatedCluster';
import $IDEE$impl$interaction$SelectCluster from './impl/ol/js/interaction/SelectedCluster';
import $IDEE$impl$handler$Feature from './impl/ol/js/handler/Feature';
import $IDEE$impl$format$XML from './impl/ol/js/format/XML';
import $IDEE$impl$format$WMTSCapabilities from './impl/ol/js/format/WMTS';
import $IDEE$impl$format$WMSCapabilities from './impl/ol/js/format/WMS';
import $IDEE$impl$format$WKT from './impl/ol/js/format/WKT';
import $IDEE$impl$format$KML from './impl/ol/js/format/KML';
import $IDEE$impl$format$GeoJSON from './impl/ol/js/format/GeoJSON';
import $IDEE$impl$format$GML from './impl/ol/js/format/GML';
import $IDEE$impl$format$DescribeFeatureType from './impl/ol/js/format/wfs/DescribeFeatureType';
import $IDEE$impl$control$ScaleLine from './impl/ol/js/control/ScaleLine';
import $IDEE$impl$control$Scale from './impl/ol/js/control/Scale';
import $IDEE$impl$control$Rotate from './impl/ol/js/control/Rotate';
import $IDEE$impl$control$Panzoombar from './impl/ol/js/control/Panzoombar';
import $IDEE$impl$control$Panzoom from './impl/ol/js/control/Panzoom';
import $IDEE$impl$control$Location from './impl/ol/js/control/Location';
import $IDEE$impl$control$GetFeatureInfo from './impl/ol/js/control/GetFeatureInfo';
import $IDEE$impl$control$Attributions from './impl/ol/js/control/Attributions';
import $IDEE$impl$View from './impl/ol/js/View';
import $IDEE$impl$Style from './impl/ol/js/style/Style';
import $IDEE$impl$RenderFeature from './impl/ol/js/feature/RenderFeature';
import $IDEE$impl$Popup from './impl/ol/js/Popup';
import $IDEE$impl$OLStyleStrokePattern from './impl/ol/js/ext/OLStyleStrokePattern';
import $IDEE$impl$OLChart from './impl/ol/js/olchart/OLChart';
import $IDEE$impl$Map from './impl/ol/js/Map';
import $IDEE$impl$Layer from './impl/ol/js/layer/Layer';
import $IDEE$impl$Label from './impl/ol/js/Label';
import $IDEE$impl$GetCapabilities from './impl/ol/js/util/WMSCapabilities';
import $IDEE$impl$Feature from './impl/ol/js/feature/Feature';
import $IDEE$impl$Control from './impl/ol/js/control/Control';
import $IDEE$i18n$plugins from './facade/js/i18n/plugins';
import $IDEE$handler$Feature from './facade/js/handler/Feature';
import $IDEE$handlebarshelpers from './facade/js/util/handlebarshelpers';
import $IDEE$format$WKT from './facade/js/format/WKT';
import $IDEE$format$GeoJSON from './facade/js/format/GeoJSON';
import $IDEE$filter$Spatial from './facade/js/filter/Spatial';
import $IDEE$filter$Function from './facade/js/filter/Function';
import $IDEE$filter$Base from './facade/js/filter/Base';
import $IDEE$facade$Base from './facade/js/Base';
import $IDEE$exception from './facade/js/exception/exception';
import $IDEE$evt$Listener from './facade/js/event/Listener';
import $IDEE$evt$EventsManager from './facade/js/event/Manager';
import $IDEE$control$ScaleLine from './facade/js/control/ScaleLine';
import $IDEE$control$Scale from './facade/js/control/Scale';
import $IDEE$control$Rotate from './facade/js/control/Rotate';
import $IDEE$control$Panzoombar from './facade/js/control/Panzoombar';
import $IDEE$control$Panzoom from './facade/js/control/Panzoom';
import $IDEE$control$Location from './facade/js/control/Location';
import $IDEE$control$GetFeatureInfo from './facade/js/control/GetFeatureInfo';
import $IDEE$control$BackgroundLayers from './facade/js/control/BackgroundLayers';
import $IDEE$control$Attributions from './facade/js/control/Attributions';
import $IDEE$Tile from './facade/js/provider/Tile';
import $IDEE$Style from './facade/js/style/Style';
import $IDEE$RenderFeature from './facade/js/feature/RenderFeature';
import $IDEE$Popup from './facade/js/Popup';
import $IDEE$Plugin from './facade/js/Plugin';
import $IDEE$Parameters from './facade/js/parameter/Parameters';
import $IDEE$Object from './facade/js/Object';
import $IDEE$Map from './facade/js/Map';
import $IDEE$Layer from './facade/js/layer/Layer';
import $IDEE$Label from './facade/js/Label';
import $IDEE$Feature from './facade/js/feature/Feature';
import $IDEE$Control from './facade/js/control/Control';
import $IDEE$ClusteredFeature from './facade/js/feature/Clustered';
const IDEE = window['IDEE'] = {};
const ol = window['ol'] = {}
IDEE.ClusteredFeature = $IDEE$ClusteredFeature;
IDEE.Control = $IDEE$Control;
IDEE.Feature = $IDEE$Feature;
IDEE.Label = $IDEE$Label;
IDEE.Layer = $IDEE$Layer;
IDEE.Map = $IDEE$Map;
IDEE.Object = $IDEE$Object;
IDEE.Parameters = $IDEE$Parameters;
IDEE.Parameters.parseBbox = IDEEParametersModule.parseBbox;
IDEE.Parameters.parseCenter = IDEEParametersModule.parseCenter;
IDEE.Parameters.parseContainer = IDEEParametersModule.parseContainer;
IDEE.Parameters.parseControls = IDEEParametersModule.parseControls;
IDEE.Parameters.parseGetFeatureInfo = IDEEParametersModule.parseGetFeatureInfo;
IDEE.Parameters.parseKML = IDEEParametersModule.parseKML;
IDEE.Parameters.parseLabel = IDEEParametersModule.parseLabel;
IDEE.Parameters.parseLayers = IDEEParametersModule.parseLayers;
IDEE.Parameters.parseMaxExtent = IDEEParametersModule.parseMaxExtent;
IDEE.Parameters.parseMaxZoom = IDEEParametersModule.parseMaxZoom;
IDEE.Parameters.parseMinZoom = IDEEParametersModule.parseMinZoom;
IDEE.Parameters.parseProjection = IDEEParametersModule.parseProjection;
IDEE.Parameters.parseResolutions = IDEEParametersModule.parseResolutions;
IDEE.Parameters.parseTicket = IDEEParametersModule.parseTicket;
IDEE.Parameters.parseViewExtent = IDEEParametersModule.parseViewExtent;
IDEE.Parameters.parseWMC = IDEEParametersModule.parseWMC;
IDEE.Parameters.parseWMS = IDEEParametersModule.parseWMS;
IDEE.Parameters.parseWMTS = IDEEParametersModule.parseWMTS;
IDEE.Parameters.parseZoom = IDEEParametersModule.parseZoom;
IDEE.Parameters.parseZoomConstrains = IDEEParametersModule.parseZoomConstrains;
IDEE.Plugin = $IDEE$Plugin;
IDEE.Popup = $IDEE$Popup;
IDEE.RenderFeature = $IDEE$RenderFeature;
IDEE.Style = $IDEE$Style;
IDEE.Tile = $IDEE$Tile;
IDEE.addQuickLayers = IDEEModule.addQuickLayers;
IDEE.config = IDEEModule.config;
IDEE.control = {};
IDEE.control.Attributions = $IDEE$control$Attributions;
IDEE.control.BackgroundLayers = $IDEE$control$BackgroundLayers;
IDEE.control.GetFeatureInfo = $IDEE$control$GetFeatureInfo;
IDEE.control.Location = $IDEE$control$Location;
IDEE.control.Panzoom = $IDEE$control$Panzoom;
IDEE.control.Panzoombar = $IDEE$control$Panzoombar;
IDEE.control.Rotate = $IDEE$control$Rotate;
IDEE.control.Scale = $IDEE$control$Scale;
IDEE.control.ScaleLine = $IDEE$control$ScaleLine;
IDEE.dialog = {};
IDEE.dialog.error = IDEEdialogModule.error;
IDEE.dialog.info = IDEEdialogModule.info;
IDEE.dialog.remove = IDEEdialogModule.remove;
IDEE.dialog.removeElement = IDEEdialogModule.removeElement;
IDEE.dialog.show = IDEEdialogModule.show;
IDEE.dialog.success = IDEEdialogModule.success;
IDEE.evt = {};
IDEE.evt.ACTIVATED = IDEEevtModule.ACTIVATED;
IDEE.evt.ADDED_GEOTIFF = IDEEevtModule.ADDED_GEOTIFF;
IDEE.evt.ADDED_KML = IDEEevtModule.ADDED_KML;
IDEE.evt.ADDED_LAYER = IDEEevtModule.ADDED_LAYER;
IDEE.evt.ADDED_LAYERGROUP = IDEEevtModule.ADDED_LAYERGROUP;
IDEE.evt.ADDED_MAPLIBRE = IDEEevtModule.ADDED_MAPLIBRE;
IDEE.evt.ADDED_MBTILES = IDEEevtModule.ADDED_MBTILES;
IDEE.evt.ADDED_MBTILES_VECTOR = IDEEevtModule.ADDED_MBTILES_VECTOR;
IDEE.evt.ADDED_OGCAPIFEATURES = IDEEevtModule.ADDED_OGCAPIFEATURES;
IDEE.evt.ADDED_QUICK_LAYERS = IDEEevtModule.ADDED_QUICK_LAYERS;
IDEE.evt.ADDED_TERRAIN = IDEEevtModule.ADDED_TERRAIN;
IDEE.evt.ADDED_TILES3D = IDEEevtModule.ADDED_TILES3D;
IDEE.evt.ADDED_TMS = IDEEevtModule.ADDED_TMS;
IDEE.evt.ADDED_TO_LAYERGROUP = IDEEevtModule.ADDED_TO_LAYERGROUP;
IDEE.evt.ADDED_TO_MAP = IDEEevtModule.ADDED_TO_MAP;
IDEE.evt.ADDED_TO_PANEL = IDEEevtModule.ADDED_TO_PANEL;
IDEE.evt.ADDED_VECTOR_TILE = IDEEevtModule.ADDED_VECTOR_TILE;
IDEE.evt.ADDED_WFS = IDEEevtModule.ADDED_WFS;
IDEE.evt.ADDED_WMS = IDEEevtModule.ADDED_WMS;
IDEE.evt.ADDED_WMTS = IDEEevtModule.ADDED_WMTS;
IDEE.evt.ADDED_XYZ = IDEEevtModule.ADDED_XYZ;
IDEE.evt.CHANGE = IDEEevtModule.CHANGE;
IDEE.evt.CHANGE_PROJ = IDEEevtModule.CHANGE_PROJ;
IDEE.evt.CHANGE_STYLE = IDEEevtModule.CHANGE_STYLE;
IDEE.evt.CHANGE_ZOOM = IDEEevtModule.CHANGE_ZOOM;
IDEE.evt.CLICK = IDEEevtModule.CLICK;
IDEE.evt.COMPLETED = IDEEevtModule.COMPLETED;
IDEE.evt.COMPLETED_CHANGE_STYLE_FEATURE = IDEEevtModule.COMPLETED_CHANGE_STYLE_FEATURE;
IDEE.evt.DEACTIVATED = IDEEevtModule.DEACTIVATED;
IDEE.evt.DESTROY = IDEEevtModule.DESTROY;
IDEE.evt.EventsManager = $IDEE$evt$EventsManager;
IDEE.evt.HIDE = IDEEevtModule.HIDE;
IDEE.evt.HOVER_FEATURES = IDEEevtModule.HOVER_FEATURES;
IDEE.evt.LAYER_VISIBILITY_CHANGE = IDEEevtModule.LAYER_VISIBILITY_CHANGE;
IDEE.evt.LEAVE_FEATURES = IDEEevtModule.LEAVE_FEATURES;
IDEE.evt.LOAD = IDEEevtModule.LOAD;
IDEE.evt.LOAD_GENERIC_VECTOR = IDEEevtModule.LOAD_GENERIC_VECTOR;
IDEE.evt.LOAD_TERRAIN = IDEEevtModule.LOAD_TERRAIN;
IDEE.evt.Listener = $IDEE$evt$Listener;
IDEE.evt.MOVE = IDEEevtModule.MOVE;
IDEE.evt.MOVE_MOUSE = IDEEevtModule.MOVE_MOUSE;
IDEE.evt.REMOVED_FROM_MAP = IDEEevtModule.REMOVED_FROM_MAP;
IDEE.evt.REMOVED_LAYER = IDEEevtModule.REMOVED_LAYER;
IDEE.evt.SELECT_FEATURES = IDEEevtModule.SELECT_FEATURES;
IDEE.evt.SHOW = IDEEevtModule.SHOW;
IDEE.evt.UNSELECT_FEATURES = IDEEevtModule.UNSELECT_FEATURES;
IDEE.exception = $IDEE$exception;
IDEE.facade = {};
IDEE.facade.Base = $IDEE$facade$Base;
IDEE.filter = {};
IDEE.filter.AND = IDEEfilterModule.AND;
IDEE.filter.Base = $IDEE$filter$Base;
IDEE.filter.EQUAL = IDEEfilterModule.EQUAL;
IDEE.filter.Function = $IDEE$filter$Function;
IDEE.filter.GT = IDEEfilterModule.GT;
IDEE.filter.GTE = IDEEfilterModule.GTE;
IDEE.filter.LIKE = IDEEfilterModule.LIKE;
IDEE.filter.LT = IDEEfilterModule.LT;
IDEE.filter.LTE = IDEEfilterModule.LTE;
IDEE.filter.NOT = IDEEfilterModule.NOT;
IDEE.filter.OR = IDEEfilterModule.OR;
IDEE.filter.Spatial = $IDEE$filter$Spatial;
IDEE.filter.spatial = {};
IDEE.filter.spatial.CONTAIN = IDEEfilterspatialModule.CONTAIN;
IDEE.filter.spatial.DISJOINT = IDEEfilterspatialModule.DISJOINT;
IDEE.filter.spatial.INTERSECT = IDEEfilterspatialModule.INTERSECT;
IDEE.filter.spatial.WITHIN = IDEEfilterspatialModule.WITHIN;
IDEE.filter.spatial.parseParamToGeometries = IDEEfilterspatialModule.parseParamToGeometries;
IDEE.format = {};
IDEE.format.GeoJSON = $IDEE$format$GeoJSON;
IDEE.format.WKT = $IDEE$format$WKT;
IDEE.geom = {};
IDEE.geom.geojson = {};
IDEE.geom.geojson.type = {};
IDEE.geom.geojson.type.GEOMETRY_COLLECTION = IDEEgeomgeojsontypeModule.GEOMETRY_COLLECTION;
IDEE.geom.geojson.type.LINE_STRING = IDEEgeomgeojsontypeModule.LINE_STRING;
IDEE.geom.geojson.type.MULTI_LINE_STRING = IDEEgeomgeojsontypeModule.MULTI_LINE_STRING;
IDEE.geom.geojson.type.MULTI_POINT = IDEEgeomgeojsontypeModule.MULTI_POINT;
IDEE.geom.geojson.type.MULTI_POLYGON = IDEEgeomgeojsontypeModule.MULTI_POLYGON;
IDEE.geom.geojson.type.POINT = IDEEgeomgeojsontypeModule.POINT;
IDEE.geom.geojson.type.POLYGON = IDEEgeomgeojsontypeModule.POLYGON;
IDEE.geom.parse = IDEEgeomModule.parse;
IDEE.geom.parseWFS = IDEEgeomModule.parseWFS;
IDEE.geom.wfs = {};
IDEE.geom.wfs.type = {};
IDEE.geom.wfs.type.LINE = IDEEgeomwfstypeModule.LINE;
IDEE.geom.wfs.type.MLINE = IDEEgeomwfstypeModule.MLINE;
IDEE.geom.wfs.type.MPOINT = IDEEgeomwfstypeModule.MPOINT;
IDEE.geom.wfs.type.MPOLYGON = IDEEgeomwfstypeModule.MPOLYGON;
IDEE.geom.wfs.type.POINT = IDEEgeomwfstypeModule.POINT;
IDEE.geom.wfs.type.POLYGON = IDEEgeomwfstypeModule.POLYGON;
IDEE.geom.wkt = {};
IDEE.geom.wkt.type = {};
IDEE.geom.wkt.type.CIRCLE = IDEEgeomwkttypeModule.CIRCLE;
IDEE.geom.wkt.type.GEOMETRY = IDEEgeomwkttypeModule.GEOMETRY;
IDEE.geom.wkt.type.GEOMETRY_COLLECTION = IDEEgeomwkttypeModule.GEOMETRY_COLLECTION;
IDEE.geom.wkt.type.LINEAR_RING = IDEEgeomwkttypeModule.LINEAR_RING;
IDEE.geom.wkt.type.LINE_STRING = IDEEgeomwkttypeModule.LINE_STRING;
IDEE.geom.wkt.type.MULTI_LINE_STRING = IDEEgeomwkttypeModule.MULTI_LINE_STRING;
IDEE.geom.wkt.type.MULTI_POINT = IDEEgeomwkttypeModule.MULTI_POINT;
IDEE.geom.wkt.type.MULTI_POLYGON = IDEEgeomwkttypeModule.MULTI_POLYGON;
IDEE.geom.wkt.type.POINT = IDEEgeomwkttypeModule.POINT;
IDEE.geom.wkt.type.POLYGON = IDEEgeomwkttypeModule.POLYGON;
IDEE.getQuickLayers = IDEEModule.getQuickLayers;
IDEE.handlebarshelpers = $IDEE$handlebarshelpers;
IDEE.handler = {};
IDEE.handler.Feature = $IDEE$handler$Feature;
IDEE.i18n = {};
IDEE.i18n.plugins = $IDEE$i18n$plugins;
IDEE.impl = {};
IDEE.impl.Control = $IDEE$impl$Control;
IDEE.impl.Feature = $IDEE$impl$Feature;
IDEE.impl.GetCapabilities = $IDEE$impl$GetCapabilities;
IDEE.impl.Label = $IDEE$impl$Label;
IDEE.impl.Layer = $IDEE$impl$Layer;
IDEE.impl.Map = $IDEE$impl$Map;
IDEE.impl.OLChart = $IDEE$impl$OLChart;
IDEE.impl.OLStyleStrokePattern = $IDEE$impl$OLStyleStrokePattern;
IDEE.impl.Popup = $IDEE$impl$Popup;
IDEE.impl.RenderFeature = $IDEE$impl$RenderFeature;
IDEE.impl.Style = $IDEE$impl$Style;
IDEE.impl.View = $IDEE$impl$View;
IDEE.impl.control = {};
IDEE.impl.control.Attributions = $IDEE$impl$control$Attributions;
IDEE.impl.control.GetFeatureInfo = $IDEE$impl$control$GetFeatureInfo;
IDEE.impl.control.Location = $IDEE$impl$control$Location;
IDEE.impl.control.Panzoom = $IDEE$impl$control$Panzoom;
IDEE.impl.control.Panzoombar = $IDEE$impl$control$Panzoombar;
IDEE.impl.control.Rotate = $IDEE$impl$control$Rotate;
IDEE.impl.control.Scale = $IDEE$impl$control$Scale;
IDEE.impl.control.Scale.formatLongNumber = IDEEimplcontrolScaleModule.formatLongNumber;
IDEE.impl.control.ScaleLine = $IDEE$impl$control$ScaleLine;
IDEE.impl.format = {};
IDEE.impl.format.DescribeFeatureType = $IDEE$impl$format$DescribeFeatureType;
IDEE.impl.format.GML = $IDEE$impl$format$GML;
IDEE.impl.format.GeoJSON = $IDEE$impl$format$GeoJSON;
IDEE.impl.format.KML = $IDEE$impl$format$KML;
IDEE.impl.format.WKT = $IDEE$impl$format$WKT;
IDEE.impl.format.WMSCapabilities = $IDEE$impl$format$WMSCapabilities;
IDEE.impl.format.WMTSCapabilities = $IDEE$impl$format$WMTSCapabilities;
IDEE.impl.format.XML = $IDEE$impl$format$XML;
IDEE.impl.handler = {};
IDEE.impl.handler.Feature = $IDEE$impl$handler$Feature;
IDEE.impl.handler.Feature.getFacadeFeature = IDEEimplhandlerFeatureModule.getFacadeFeature;
IDEE.impl.interaction = {};
IDEE.impl.interaction.SelectCluster = $IDEE$impl$interaction$SelectCluster;
IDEE.impl.layer = {};
IDEE.impl.layer.AnimatedCluster = $IDEE$impl$layer$AnimatedCluster;
IDEE.impl.layer.Draw = $IDEE$impl$layer$Draw;
IDEE.impl.layer.GenericRaster = $IDEE$impl$layer$GenericRaster;
IDEE.impl.layer.GenericVector = $IDEE$impl$layer$GenericVector;
IDEE.impl.layer.GeoJSON = $IDEE$impl$layer$GeoJSON;
IDEE.impl.layer.GeoTIFF = $IDEE$impl$layer$GeoTIFF;
IDEE.impl.layer.Heatmap = $IDEE$impl$layer$Heatmap;
IDEE.impl.layer.KML = $IDEE$impl$layer$KML;
IDEE.impl.layer.LayerGroup = $IDEE$impl$layer$LayerGroup;
IDEE.impl.layer.MBTiles = $IDEE$impl$layer$MBTiles;
IDEE.impl.layer.MBTilesVector = $IDEE$impl$layer$MBTilesVector;
IDEE.impl.layer.MVT = $IDEE$impl$layer$MVT;
IDEE.impl.layer.MapLibre = $IDEE$impl$layer$MapLibre;
IDEE.impl.layer.OGCAPIFeatures = $IDEE$impl$layer$OGCAPIFeatures;
IDEE.impl.layer.OSM = $IDEE$impl$layer$OSM;
IDEE.impl.layer.TMS = $IDEE$impl$layer$TMS;
IDEE.impl.layer.Vector = $IDEE$impl$layer$Vector;
IDEE.impl.layer.WFS = $IDEE$impl$layer$WFS;
IDEE.impl.layer.WMS = $IDEE$impl$layer$WMS;
IDEE.impl.layer.WMTS = $IDEE$impl$layer$WMTS;
IDEE.impl.layer.XYZ = $IDEE$impl$layer$XYZ;
IDEE.impl.loadFiles = $IDEE$impl$loadFiles;
IDEE.impl.loader = {};
IDEE.impl.loader.JSONP = $IDEE$impl$loader$JSONP;
IDEE.impl.loader.KML = $IDEE$impl$loader$KML;
IDEE.impl.loader.WFS = $IDEE$impl$loader$WFS;
IDEE.impl.ol = {};
IDEE.impl.ol.js = {};
IDEE.impl.ol.js.ext = {};
IDEE.impl.ol.js.ext.GeomUtils = $IDEE$impl$ol$js$ext$GeomUtils;
IDEE.impl.ol.js.ext.OLStyleFillPattern = $IDEE$impl$ol$js$ext$OLStyleFillPattern;
IDEE.impl.ol.js.ext.OLStyleFlowLine = $IDEE$impl$ol$js$ext$OLStyleFlowLine;
IDEE.impl.ol.js.ext.OLStyleFontSymbol = $IDEE$impl$ol$js$ext$OLStyleFontSymbol;
IDEE.impl.ol.js.ext.cspline = $IDEE$impl$ol$js$ext$cspline;
IDEE.impl.ol.js.patches = {};
IDEE.impl.ol.js.patches.optionsFromCapabilities = IDEEimploljspatchesModule.optionsFromCapabilities;
IDEE.impl.ol.js.projections = $IDEE$impl$ol$js$projections;
IDEE.impl.service = {};
IDEE.impl.service.OGCAPIFeatures = $IDEE$impl$service$OGCAPIFeatures;
IDEE.impl.service.WFS = $IDEE$impl$service$WFS;
IDEE.impl.source = {};
IDEE.impl.source.ImageWMS = $IDEE$impl$source$ImageWMS;
IDEE.impl.source.TileWMS = $IDEE$impl$source$TileWMS;
IDEE.impl.style = {};
IDEE.impl.style.Centroid = $IDEE$impl$style$Centroid;
IDEE.impl.style.Chart = $IDEE$impl$style$Chart;
IDEE.impl.style.Chart.extend = IDEEimplstyleChartModule.extend;
IDEE.impl.style.Chart.generateTextBarChart = IDEEimplstyleChartModule.generateTextBarChart;
IDEE.impl.style.Chart.generateTextCircleChart = IDEEimplstyleChartModule.generateTextCircleChart;
IDEE.impl.style.Chart.getTextData = IDEEimplstyleChartModule.getTextData;
IDEE.impl.style.Cluster = $IDEE$impl$style$Cluster;
IDEE.impl.style.Feature = $IDEE$impl$style$Feature;
IDEE.impl.style.FlowLine = $IDEE$impl$style$FlowLine;
IDEE.impl.style.Generic = $IDEE$impl$style$Generic;
IDEE.impl.style.Heatmap = $IDEE$impl$style$Heatmap;
IDEE.impl.style.Icon = $IDEE$impl$style$Icon;
IDEE.impl.style.Line = $IDEE$impl$style$Line;
IDEE.impl.style.Path = $IDEE$impl$style$Path;
IDEE.impl.style.Point = $IDEE$impl$style$Point;
IDEE.impl.style.Polygon = $IDEE$impl$style$Polygon;
IDEE.impl.style.Simple = $IDEE$impl$style$Simple;
IDEE.impl.style.builder = {};
IDEE.impl.style.builder.getFill = IDEEimplstylebuilderModule.getFill;
IDEE.impl.style.builder.getFillPatern = IDEEimplstylebuilderModule.getFillPatern;
IDEE.impl.style.builder.getIconForm = IDEEimplstylebuilderModule.getIconForm;
IDEE.impl.style.builder.getIconSrc = IDEEimplstylebuilderModule.getIconSrc;
IDEE.impl.style.builder.getLabel = IDEEimplstylebuilderModule.getLabel;
IDEE.impl.style.builder.getLineStroke = IDEEimplstylebuilderModule.getLineStroke;
IDEE.impl.style.builder.getLineStyle = IDEEimplstylebuilderModule.getLineStyle;
IDEE.impl.style.builder.getLineText = IDEEimplstylebuilderModule.getLineText;
IDEE.impl.style.builder.getPointStyle = IDEEimplstylebuilderModule.getPointStyle;
IDEE.impl.style.builder.getPolygonStyle = IDEEimplstylebuilderModule.getPolygonStyle;
IDEE.impl.style.builder.getStroke = IDEEimplstylebuilderModule.getStroke;
IDEE.impl.style.builder.getStrokePatern = IDEEimplstylebuilderModule.getStrokePatern;
IDEE.impl.style.builder.iconCache = IDEEimplstylebuilderModule.iconCache;
IDEE.impl.util = {};
IDEE.impl.util.TextPath = $IDEE$impl$util$TextPath;
IDEE.impl.util.wmtscapabilities = $IDEE$impl$util$wmtscapabilities;
IDEE.impl.util.wmtscapabilities = $IDEE$impl$util$wmtscapabilities;
IDEE.impl.util.wmtscapabilities.getLayers = IDEEimplutilwmtscapabilitiesModule.getLayers;
IDEE.impl.utils = $IDEE$impl$utils;
IDEE.impl.utils = $IDEE$impl$utils;
IDEE.impl.utils.geojsonTo4326 = IDEEimplutilsModule.geojsonTo4326;
IDEE.language = {};
IDEE.language.addTranslation = IDEElanguageModule.addTranslation;
IDEE.language.configuration = IDEElanguageModule.configuration;
IDEE.language.getLang = IDEElanguageModule.getLang;
IDEE.language.getTranslation = IDEElanguageModule.getTranslation;
IDEE.language.getValue = IDEElanguageModule.getValue;
IDEE.language.setLang = IDEElanguageModule.setLang;
IDEE.layer = {};
IDEE.layer.GenericRaster = $IDEE$layer$GenericRaster;
IDEE.layer.GenericVector = $IDEE$layer$GenericVector;
IDEE.layer.GeoJSON = $IDEE$layer$GeoJSON;
IDEE.layer.GeoTIFF = $IDEE$layer$GeoTIFF;
IDEE.layer.KML = $IDEE$layer$KML;
IDEE.layer.LayerGroup = $IDEE$layer$LayerGroup;
IDEE.layer.MBTiles = $IDEE$layer$MBTiles;
IDEE.layer.MBTilesVector = $IDEE$layer$MBTilesVector;
IDEE.layer.MBTilesVector.mode = IDEElayerMBTilesVectorModule.mode;
IDEE.layer.MVT = $IDEE$layer$MVT;
IDEE.layer.MVT.mode = IDEElayerMVTModule.mode;
IDEE.layer.MapLibre = $IDEE$layer$MapLibre;
IDEE.layer.OGCAPIFeatures = $IDEE$layer$OGCAPIFeatures;
IDEE.layer.OSM = $IDEE$layer$OSM;
IDEE.layer.TMS = $IDEE$layer$TMS;
IDEE.layer.Terrain = $IDEE$layer$Terrain;
IDEE.layer.Tiles3D = $IDEE$layer$Tiles3D;
IDEE.layer.Vector = $IDEE$layer$Vector;
IDEE.layer.WFS = $IDEE$layer$WFS;
IDEE.layer.WMS = $IDEE$layer$WMS;
IDEE.layer.WMTS = $IDEE$layer$WMTS;
IDEE.layer.XYZ = $IDEE$layer$XYZ;
IDEE.layer.type = {};
IDEE.layer.type.GenericRaster = IDEElayertypeModule.GenericRaster;
IDEE.layer.type.GenericVector = IDEElayertypeModule.GenericVector;
IDEE.layer.type.GeoJSON = IDEElayertypeModule.GeoJSON;
IDEE.layer.type.GeoTIFF = IDEElayertypeModule.GeoTIFF;
IDEE.layer.type.KML = IDEElayertypeModule.KML;
IDEE.layer.type.LayerGroup = IDEElayertypeModule.LayerGroup;
IDEE.layer.type.MBTiles = IDEElayertypeModule.MBTiles;
IDEE.layer.type.MBTilesVector = IDEElayertypeModule.MBTilesVector;
IDEE.layer.type.MVT = IDEElayertypeModule.MVT;
IDEE.layer.type.MapLibre = IDEElayertypeModule.MapLibre;
IDEE.layer.type.OGCAPIFeatures = IDEElayertypeModule.OGCAPIFeatures;
IDEE.layer.type.OSM = IDEElayertypeModule.OSM;
IDEE.layer.type.TMS = IDEElayertypeModule.TMS;
IDEE.layer.type.Tiles3D = IDEElayertypeModule.Tiles3D;
IDEE.layer.type.Vector = IDEElayertypeModule.Vector;
IDEE.layer.type.WFS = IDEElayertypeModule.WFS;
IDEE.layer.type.WMS = IDEElayertypeModule.WMS;
IDEE.layer.type.WMTS = IDEElayertypeModule.WMTS;
IDEE.layer.type.XYZ = IDEElayertypeModule.XYZ;
IDEE.loadFiles = {};
IDEE.loadFiles.addFileToMap = IDEEloadFilesModule.addFileToMap;
IDEE.loadFiles.loadFeaturesFromSource = IDEEloadFilesModule.loadFeaturesFromSource;
IDEE.map = IDEEModule.map;
IDEE.parameter = $IDEE$parameter;
IDEE.parameter = $IDEE$parameter;
IDEE.parameter.geojson = IDEEparameterModule.geojson;
IDEE.parameter.geotiff = IDEEparameterModule.geotiff;
IDEE.parameter.getBlobGeoTIFF = IDEEparameterModule.getBlobGeoTIFF;
IDEE.parameter.getCQLWFS = IDEEparameterModule.getCQLWFS;
IDEE.parameter.getConditionalOGC = IDEEparameterModule.getConditionalOGC;
IDEE.parameter.getDisplayInLayerSwitcherGeoTIFF = IDEEparameterModule.getDisplayInLayerSwitcherGeoTIFF;
IDEE.parameter.getDisplayInLayerSwitcherTerrain = IDEEparameterModule.getDisplayInLayerSwitcherTerrain;
IDEE.parameter.getDisplayInLayerSwitcherTiles3D = IDEEparameterModule.getDisplayInLayerSwitcherTiles3D;
IDEE.parameter.getDisplayInLayerSwitcherWMS = IDEEparameterModule.getDisplayInLayerSwitcherWMS;
IDEE.parameter.getDisplayInLayerSwitcherWMTS = IDEEparameterModule.getDisplayInLayerSwitcherWMTS;
IDEE.parameter.getExtraParameter = IDEEparameterModule.getExtraParameter;
IDEE.parameter.getExtractGeoJSON = IDEEparameterModule.getExtractGeoJSON;
IDEE.parameter.getExtractKML = IDEEparameterModule.getExtractKML;
IDEE.parameter.getExtractMBTilesVector = IDEEparameterModule.getExtractMBTilesVector;
IDEE.parameter.getExtractOGC = IDEEparameterModule.getExtractOGC;
IDEE.parameter.getExtractWFS = IDEEparameterModule.getExtractWFS;
IDEE.parameter.getFormatWMTS = IDEEparameterModule.getFormatWMTS;
IDEE.parameter.getGeometryWFS = IDEEparameterModule.getGeometryWFS;
IDEE.parameter.getIdsWFS = IDEEparameterModule.getIdsWFS;
IDEE.parameter.getLabelKML = IDEEparameterModule.getLabelKML;
IDEE.parameter.getLegendGeoJSON = IDEEparameterModule.getLegendGeoJSON;
IDEE.parameter.getLegendGeoTIFF = IDEEparameterModule.getLegendGeoTIFF;
IDEE.parameter.getLegendKML = IDEEparameterModule.getLegendKML;
IDEE.parameter.getLegendMBTiles = IDEEparameterModule.getLegendMBTiles;
IDEE.parameter.getLegendMBTilesVector = IDEEparameterModule.getLegendMBTilesVector;
IDEE.parameter.getLegendMVT = IDEEparameterModule.getLegendMVT;
IDEE.parameter.getLegendTerrain = IDEEparameterModule.getLegendTerrain;
IDEE.parameter.getLegendTiles3D = IDEEparameterModule.getLegendTiles3D;
IDEE.parameter.getLegendWFS = IDEEparameterModule.getLegendWFS;
IDEE.parameter.getLegendWMS = IDEEparameterModule.getLegendWMS;
IDEE.parameter.getLegendWMTS = IDEEparameterModule.getLegendWMTS;
IDEE.parameter.getMatrixSetWMTS = IDEEparameterModule.getMatrixSetWMTS;
IDEE.parameter.getMaxExtentMBTiles = IDEEparameterModule.getMaxExtentMBTiles;
IDEE.parameter.getMaxExtentMBTilesVector = IDEEparameterModule.getMaxExtentMBTilesVector;
IDEE.parameter.getMaxExtentWMS = IDEEparameterModule.getMaxExtentWMS;
IDEE.parameter.getMaxZoomLevelMBTiles = IDEEparameterModule.getMaxZoomLevelMBTiles;
IDEE.parameter.getNameGeoTIFF = IDEEparameterModule.getNameGeoTIFF;
IDEE.parameter.getNameKML = IDEEparameterModule.getNameKML;
IDEE.parameter.getNameMBTiles = IDEEparameterModule.getNameMBTiles;
IDEE.parameter.getNameMBTilesVector = IDEEparameterModule.getNameMBTilesVector;
IDEE.parameter.getNameMVT = IDEEparameterModule.getNameMVT;
IDEE.parameter.getNameTMS = IDEEparameterModule.getNameTMS;
IDEE.parameter.getNameTerrain = IDEEparameterModule.getNameTerrain;
IDEE.parameter.getNameTiles3D = IDEEparameterModule.getNameTiles3D;
IDEE.parameter.getNameWFS = IDEEparameterModule.getNameWFS;
IDEE.parameter.getNameWMS = IDEEparameterModule.getNameWMS;
IDEE.parameter.getNameWMTS = IDEEparameterModule.getNameWMTS;
IDEE.parameter.getNameXYZ = IDEEparameterModule.getNameXYZ;
IDEE.parameter.getNamespaceWFS = IDEEparameterModule.getNamespaceWFS;
IDEE.parameter.getNormalizeGeoTIFF = IDEEparameterModule.getNormalizeGeoTIFF;
IDEE.parameter.getOpacityMBTiles = IDEEparameterModule.getOpacityMBTiles;
IDEE.parameter.getOptionsWFS = IDEEparameterModule.getOptionsWFS;
IDEE.parameter.getOptionsWMS = IDEEparameterModule.getOptionsWMS;
IDEE.parameter.getOptionsWMTS = IDEEparameterModule.getOptionsWMTS;
IDEE.parameter.getProjectionGeoTIFF = IDEEparameterModule.getProjectionGeoTIFF;
IDEE.parameter.getQueryableWMS = IDEEparameterModule.getQueryableWMS;
IDEE.parameter.getQueryableWMTS = IDEEparameterModule.getQueryableWMTS;
IDEE.parameter.getSourceGeoJSON = IDEEparameterModule.getSourceGeoJSON;
IDEE.parameter.getSourceMBTiles = IDEEparameterModule.getSourceMBTiles;
IDEE.parameter.getSourceMBTilesVector = IDEEparameterModule.getSourceMBTilesVector;
IDEE.parameter.getStyleGeoJSON = IDEEparameterModule.getStyleGeoJSON;
IDEE.parameter.getStyleMBTilesVector = IDEEparameterModule.getStyleMBTilesVector;
IDEE.parameter.getStyleOGC = IDEEparameterModule.getStyleOGC;
IDEE.parameter.getStyleWFS = IDEEparameterModule.getStyleWFS;
IDEE.parameter.getTileLoadFunctionMBTiles = IDEEparameterModule.getTileLoadFunctionMBTiles;
IDEE.parameter.getTileLoadFunctionMBTilesVector = IDEEparameterModule.getTileLoadFunctionMBTilesVector;
IDEE.parameter.getTileSizeMBTiles = IDEEparameterModule.getTileSizeMBTiles;
IDEE.parameter.getTileSizeMBTilesVector = IDEEparameterModule.getTileSizeMBTilesVector;
IDEE.parameter.getTiledWMS = IDEEparameterModule.getTiledWMS;
IDEE.parameter.getTransparentGeoTIFF = IDEEparameterModule.getTransparentGeoTIFF;
IDEE.parameter.getTransparentMBTiles = IDEEparameterModule.getTransparentMBTiles;
IDEE.parameter.getTransparentWMS = IDEEparameterModule.getTransparentWMS;
IDEE.parameter.getTransparentWMTS = IDEEparameterModule.getTransparentWMTS;
IDEE.parameter.getType = IDEEparameterModule.getType;
IDEE.parameter.getURLGeoJSON = IDEEparameterModule.getURLGeoJSON;
IDEE.parameter.getURLGeoTIFF = IDEEparameterModule.getURLGeoTIFF;
IDEE.parameter.getURLKML = IDEEparameterModule.getURLKML;
IDEE.parameter.getURLMBTiles = IDEEparameterModule.getURLMBTiles;
IDEE.parameter.getURLMBTilesVector = IDEEparameterModule.getURLMBTilesVector;
IDEE.parameter.getURLMVT = IDEEparameterModule.getURLMVT;
IDEE.parameter.getURLTerrain = IDEEparameterModule.getURLTerrain;
IDEE.parameter.getURLTiles3D = IDEEparameterModule.getURLTiles3D;
IDEE.parameter.getURLWFS = IDEEparameterModule.getURLWFS;
IDEE.parameter.getURLWMS = IDEEparameterModule.getURLWMS;
IDEE.parameter.getURLWMTS = IDEEparameterModule.getURLWMTS;
IDEE.parameter.getURLXYZSource = IDEEparameterModule.getURLXYZSource;
IDEE.parameter.getUseCapabilitiesWMS = IDEEparameterModule.getUseCapabilitiesWMS;
IDEE.parameter.getVersionWFS = IDEEparameterModule.getVersionWFS;
IDEE.parameter.getVersionWMS = IDEEparameterModule.getVersionWMS;
IDEE.parameter.getVisibilityGeoTIFF = IDEEparameterModule.getVisibilityGeoTIFF;
IDEE.parameter.getVisibilityKML = IDEEparameterModule.getVisibilityKML;
IDEE.parameter.getVisibilityMBTiles = IDEEparameterModule.getVisibilityMBTiles;
IDEE.parameter.getVisibilityMBTilesVector = IDEEparameterModule.getVisibilityMBTilesVector;
IDEE.parameter.getVisibilityTerrain = IDEEparameterModule.getVisibilityTerrain;
IDEE.parameter.getVisibilityTiles3D = IDEEparameterModule.getVisibilityTiles3D;
IDEE.parameter.getVisibilityWMS = IDEEparameterModule.getVisibilityWMS;
IDEE.parameter.getVisibilityWMTS = IDEEparameterModule.getVisibilityWMTS;
IDEE.parameter.kml = IDEEparameterModule.kml;
IDEE.parameter.layer = IDEEparameterModule.layer;
IDEE.parameter.maxExtent = IDEEparameterModule.maxExtent;
IDEE.parameter.maxZoom = IDEEparameterModule.maxZoom;
IDEE.parameter.mbtiles = $IDEE$parameter$mbtiles;
IDEE.parameter.mbtiles = IDEEparameterModule.mbtiles;
IDEE.parameter.mbtilesvector = $IDEE$parameter$mbtilesvector;
IDEE.parameter.mbtilesvector = IDEEparameterModule.mbtilesvector;
IDEE.parameter.minZoom = IDEEparameterModule.minZoom;
IDEE.parameter.mvt = IDEEparameterModule.mvt;
IDEE.parameter.ogcapifeatures = IDEEparameterModule.ogcapifeatures;
IDEE.parameter.projection = IDEEparameterModule.projection;
IDEE.parameter.resolutions = IDEEparameterModule.resolutions;
IDEE.parameter.terrain = IDEEparameterModule.terrain;
IDEE.parameter.tiles3d = IDEEparameterModule.tiles3d;
IDEE.parameter.tms = $IDEE$parameter$tms;
IDEE.parameter.tms = IDEEparameterModule.tms;
IDEE.parameter.wfs = IDEEparameterModule.wfs;
IDEE.parameter.wms = IDEEparameterModule.wms;
IDEE.parameter.wmts = IDEEparameterModule.wmts;
IDEE.parameter.xyz = $IDEE$parameter$xyz;
IDEE.parameter.xyz = IDEEparameterModule.xyz;
IDEE.parameter.zoom = IDEEparameterModule.zoom;
IDEE.polyfill = $IDEE$polyfill;
IDEE.proxy = IDEEModule.proxy;
IDEE.remote = $IDEE$remote;
IDEE.remote.Response = $IDEE$remote$Response;
IDEE.remote.get = IDEEremoteModule.get;
IDEE.remote.method = IDEEremoteModule.method;
IDEE.remote.post = IDEEremoteModule.post;
IDEE.style = {};
IDEE.style.Category = $IDEE$style$Category;
IDEE.style.Chart = $IDEE$style$Chart;
IDEE.style.Choropleth = $IDEE$style$Choropleth;
IDEE.style.Cluster = $IDEE$style$Cluster;
IDEE.style.Composite = $IDEE$style$Composite;
IDEE.style.Feature = $IDEE$style$Feature;
IDEE.style.FlowLine = $IDEE$style$FlowLine;
IDEE.style.Font = $IDEE$style$Font;
IDEE.style.Generic = $IDEE$style$Generic;
IDEE.style.Heatmap = $IDEE$style$Heatmap;
IDEE.style.Line = $IDEE$style$Line;
IDEE.style.Point = $IDEE$style$Point;
IDEE.style.Polygon = $IDEE$style$Polygon;
IDEE.style.Proportional = $IDEE$style$Proportional;
IDEE.style.Proportional.getMinMaxValues = IDEEstyleProportionalModule.getMinMaxValues;
IDEE.style.Simple = $IDEE$style$Simple;
IDEE.style.align = {};
IDEE.style.align.CENTER = IDEEstylealignModule.CENTER;
IDEE.style.align.JUSTIFY = IDEEstylealignModule.JUSTIFY;
IDEE.style.align.LEFT = IDEEstylealignModule.LEFT;
IDEE.style.align.RIGHT = IDEEstylealignModule.RIGHT;
IDEE.style.baseline = {};
IDEE.style.baseline.ALPHABETIC = IDEEstylebaselineModule.ALPHABETIC;
IDEE.style.baseline.BASELINE = IDEEstylebaselineModule.BASELINE;
IDEE.style.baseline.BOTTOM = IDEEstylebaselineModule.BOTTOM;
IDEE.style.baseline.CENTER = IDEEstylebaselineModule.CENTER;
IDEE.style.baseline.HANGING = IDEEstylebaselineModule.HANGING;
IDEE.style.baseline.IDEOGRAPHIC = IDEEstylebaselineModule.IDEOGRAPHIC;
IDEE.style.baseline.MIDDLE = IDEEstylebaselineModule.MIDDLE;
IDEE.style.baseline.TOP = IDEEstylebaselineModule.TOP;
IDEE.style.chart = {};
IDEE.style.chart.Variable = $IDEE$style$chart$Variable;
IDEE.style.chart.schemes = IDEEstylechartModule.schemes;
IDEE.style.chart.types = IDEEstylechartModule.types;
IDEE.style.form = {};
IDEE.style.form.BAN = IDEEstyleformModule.BAN;
IDEE.style.form.BLAZON = IDEEstyleformModule.BLAZON;
IDEE.style.form.BUBBLE = IDEEstyleformModule.BUBBLE;
IDEE.style.form.CIRCLE = IDEEstyleformModule.CIRCLE;
IDEE.style.form.LOZENGE = IDEEstyleformModule.LOZENGE;
IDEE.style.form.MARKER = IDEEstyleformModule.MARKER;
IDEE.style.form.NONE = IDEEstyleformModule.NONE;
IDEE.style.form.SHIELD = IDEEstyleformModule.SHIELD;
IDEE.style.form.SIGN = IDEEstyleformModule.SIGN;
IDEE.style.form.SQUARE = IDEEstyleformModule.SQUARE;
IDEE.style.form.TRIANGLE = IDEEstyleformModule.TRIANGLE;
IDEE.style.heightReference = {};
IDEE.style.heightReference.CLAMP_TO_3D_TILE = IDEEstyleheightReferenceModule.CLAMP_TO_3D_TILE;
IDEE.style.heightReference.CLAMP_TO_GROUND = IDEEstyleheightReferenceModule.CLAMP_TO_GROUND;
IDEE.style.heightReference.CLAMP_TO_TERRAIN = IDEEstyleheightReferenceModule.CLAMP_TO_TERRAIN;
IDEE.style.heightReference.NONE = IDEEstyleheightReferenceModule.NONE;
IDEE.style.heightReference.RELATIVE_TO_3D_TILE = IDEEstyleheightReferenceModule.RELATIVE_TO_3D_TILE;
IDEE.style.heightReference.RELATIVE_TO_GROUND = IDEEstyleheightReferenceModule.RELATIVE_TO_GROUND;
IDEE.style.heightReference.RELATIVE_TO_TERRAIN = IDEEstyleheightReferenceModule.RELATIVE_TO_TERRAIN;
IDEE.style.pattern = {};
IDEE.style.pattern.BRECCIA = IDEEstylepatternModule.BRECCIA;
IDEE.style.pattern.BRICK = IDEEstylepatternModule.BRICK;
IDEE.style.pattern.CAPS = IDEEstylepatternModule.CAPS;
IDEE.style.pattern.CEMETRY = IDEEstylepatternModule.CEMETRY;
IDEE.style.pattern.CHAOS = IDEEstylepatternModule.CHAOS;
IDEE.style.pattern.CHARACTER = IDEEstylepatternModule.CHARACTER;
IDEE.style.pattern.CIRCLE = IDEEstylepatternModule.CIRCLE;
IDEE.style.pattern.CLAY = IDEEstylepatternModule.CLAY;
IDEE.style.pattern.COAL = IDEEstylepatternModule.COAL;
IDEE.style.pattern.CONGLOMERATE = IDEEstylepatternModule.CONGLOMERATE;
IDEE.style.pattern.CROSS = IDEEstylepatternModule.CROSS;
IDEE.style.pattern.CROSSES = IDEEstylepatternModule.CROSSES;
IDEE.style.pattern.DOLOMITE = IDEEstylepatternModule.DOLOMITE;
IDEE.style.pattern.DOT = IDEEstylepatternModule.DOT;
IDEE.style.pattern.FLOODED = IDEEstylepatternModule.FLOODED;
IDEE.style.pattern.FOREST = IDEEstylepatternModule.FOREST;
IDEE.style.pattern.GRASS = IDEEstylepatternModule.GRASS;
IDEE.style.pattern.GRAVEL = IDEEstylepatternModule.GRAVEL;
IDEE.style.pattern.HATCH = IDEEstylepatternModule.HATCH;
IDEE.style.pattern.HEXAGON = IDEEstylepatternModule.HEXAGON;
IDEE.style.pattern.ICON = IDEEstylepatternModule.ICON;
IDEE.style.pattern.IMAGE = IDEEstylepatternModule.IMAGE;
IDEE.style.pattern.NYLON = IDEEstylepatternModule.NYLON;
IDEE.style.pattern.PINE = IDEEstylepatternModule.PINE;
IDEE.style.pattern.PINES = IDEEstylepatternModule.PINES;
IDEE.style.pattern.ROCK = IDEEstylepatternModule.ROCK;
IDEE.style.pattern.ROCKS = IDEEstylepatternModule.ROCKS;
IDEE.style.pattern.SAND = IDEEstylepatternModule.SAND;
IDEE.style.pattern.SCRUB = IDEEstylepatternModule.SCRUB;
IDEE.style.pattern.SQUARE = IDEEstylepatternModule.SQUARE;
IDEE.style.pattern.SWAMP = IDEEstylepatternModule.SWAMP;
IDEE.style.pattern.TILE = IDEEstylepatternModule.TILE;
IDEE.style.pattern.TREE = IDEEstylepatternModule.TREE;
IDEE.style.pattern.VINE = IDEEstylepatternModule.VINE;
IDEE.style.pattern.WAVE = IDEEstylepatternModule.WAVE;
IDEE.style.pattern.WOVEN = IDEEstylepatternModule.WOVEN;
IDEE.style.quantification = {};
IDEE.style.quantification.ARITHMETIC_PROGRESSION = IDEEstylequantificationModule.ARITHMETIC_PROGRESSION;
IDEE.style.quantification.EQUAL_INTERVAL = IDEEstylequantificationModule.EQUAL_INTERVAL;
IDEE.style.quantification.GEOMETRIC_PROGRESSION = IDEEstylequantificationModule.GEOMETRIC_PROGRESSION;
IDEE.style.quantification.JENKS = IDEEstylequantificationModule.JENKS;
IDEE.style.quantification.MEDIA_SIGMA = IDEEstylequantificationModule.MEDIA_SIGMA;
IDEE.style.quantification.QUANTILE = IDEEstylequantificationModule.QUANTILE;
IDEE.style.quantification.hasNegativeValue = IDEEstylequantificationModule.hasNegativeValue;
IDEE.style.quantification.uniqueArray = IDEEstylequantificationModule.uniqueArray;
IDEE.style.state = {};
IDEE.style.state.DEFAULT = IDEEstylestateModule.DEFAULT;
IDEE.style.state.NEW = IDEEstylestateModule.NEW;
IDEE.style.state.SELECTED = IDEEstylestateModule.SELECTED;
IDEE.style.textoverflow = {};
IDEE.style.textoverflow.ELLIPSIS = IDEEstyletextoverflowModule.ELLIPSIS;
IDEE.style.textoverflow.HIDDEN = IDEEstyletextoverflowModule.HIDDEN;
IDEE.style.textoverflow.VISIBLE = IDEEstyletextoverflowModule.VISIBLE;
IDEE.template = $IDEE$template;
IDEE.template.add = IDEEtemplateModule.add;
IDEE.template.compile = IDEEtemplateModule.compile;
IDEE.template.compileSync = IDEEtemplateModule.compileSync;
IDEE.template.get = IDEEtemplateModule.get;
IDEE.toast = {};
IDEE.toast.error = IDEEtoastModule.error;
IDEE.toast.info = IDEEtoastModule.info;
IDEE.toast.remove = IDEEtoastModule.remove;
IDEE.toast.removeElement = IDEEtoastModule.removeElement;
IDEE.toast.show = IDEEtoastModule.show;
IDEE.toast.success = IDEEtoastModule.success;
IDEE.toast.warning = IDEEtoastModule.warning;
IDEE.ui = {};
IDEE.ui.Panel = $IDEE$ui$Panel;
IDEE.ui.position = {};
IDEE.ui.position.BL = IDEEuipositionModule.BL;
IDEE.ui.position.BR = IDEEuipositionModule.BR;
IDEE.ui.position.TL = IDEEuipositionModule.TL;
IDEE.ui.position.TR = IDEEuipositionModule.TR;
IDEE.units = {};
IDEE.units.DOTS_PER_INCH = IDEEunitsModule.DOTS_PER_INCH;
IDEE.units.INCHES_PER_UNIT = IDEEunitsModule.INCHES_PER_UNIT;
IDEE.useproxy = IDEEModule.useproxy;
IDEE.utils = $IDEE$utils;
IDEE.utils.ObjectToArrayExtent = IDEEutilsModule.ObjectToArrayExtent;
IDEE.utils.addParameters = IDEEutilsModule.addParameters;
IDEE.utils.beautifyAttribute = IDEEutilsModule.beautifyAttribute;
IDEE.utils.beautifyAttributeName = IDEEutilsModule.beautifyAttributeName;
IDEE.utils.beautifyString = IDEEutilsModule.beautifyString;
IDEE.utils.bytesToBase64 = IDEEutilsModule.bytesToBase64;
IDEE.utils.concatUrlPaths = IDEEutilsModule.concatUrlPaths;
IDEE.utils.copyImageClipBoard = IDEEutilsModule.copyImageClipBoard;
IDEE.utils.decodeBase64 = IDEEutilsModule.decodeBase64;
IDEE.utils.decodeHtml = IDEEutilsModule.decodeHtml;
IDEE.utils.defineFunctionFromString = IDEEutilsModule.defineFunctionFromString;
IDEE.utils.dragElement = IDEEutilsModule.dragElement;
IDEE.utils.draggabillyElement = IDEEutilsModule.draggabillyElement;
IDEE.utils.draggabillyPlugin = IDEEutilsModule.draggabillyPlugin;
IDEE.utils.drawDynamicStyle = IDEEutilsModule.drawDynamicStyle;
IDEE.utils.enableTouchScroll = IDEEutilsModule.enableTouchScroll;
IDEE.utils.encodeBase64 = IDEEutilsModule.encodeBase64;
IDEE.utils.escapeJSCode = IDEEutilsModule.escapeJSCode;
IDEE.utils.escapeXSS = IDEEutilsModule.escapeXSS;
IDEE.utils.extend = IDEEutilsModule.extend;
IDEE.utils.extendsObj = IDEEutilsModule.extendsObj;
IDEE.utils.fillResolutions = IDEEutilsModule.fillResolutions;
IDEE.utils.findUrls = IDEEutilsModule.findUrls;
IDEE.utils.generateColorScale = IDEEutilsModule.generateColorScale;
IDEE.utils.generateIntervals = IDEEutilsModule.generateIntervals;
IDEE.utils.generateRandom = IDEEutilsModule.generateRandom;
IDEE.utils.generateResolutionsFromExtent = IDEEutilsModule.generateResolutionsFromExtent;
IDEE.utils.generateResolutionsFromScales = IDEEutilsModule.generateResolutionsFromScales;
IDEE.utils.getEnvolvedExtent = IDEEutilsModule.getEnvolvedExtent;
IDEE.utils.getImageMap = IDEEutilsModule.getImageMap;
IDEE.utils.getImageSize = IDEEutilsModule.getImageSize;
IDEE.utils.getOpacityFromRgba = IDEEutilsModule.getOpacityFromRgba;
IDEE.utils.getParameterValue = IDEEutilsModule.getParameterValue;
IDEE.utils.getResolutionFromScale = IDEEutilsModule.getResolutionFromScale;
IDEE.utils.getRgba = IDEEutilsModule.getRgba;
IDEE.utils.getScaleFromResolution = IDEEutilsModule.getScaleFromResolution;
IDEE.utils.getTextFromHtml = IDEEutilsModule.getTextFromHtml;
IDEE.utils.getWMSGetCapabilitiesUrl = IDEEutilsModule.getWMSGetCapabilitiesUrl;
IDEE.utils.getWMTSGetCapabilitiesUrl = IDEEutilsModule.getWMTSGetCapabilitiesUrl;
IDEE.utils.htmlToString = IDEEutilsModule.htmlToString;
IDEE.utils.includes = IDEEutilsModule.includes;
IDEE.utils.inverseColor = IDEEutilsModule.inverseColor;
IDEE.utils.isArray = IDEEutilsModule.isArray;
IDEE.utils.isBoolean = IDEEutilsModule.isBoolean;
IDEE.utils.isDynamic = IDEEutilsModule.isDynamic;
IDEE.utils.isFunction = IDEEutilsModule.isFunction;
IDEE.utils.isGeometryType = IDEEutilsModule.isGeometryType;
IDEE.utils.isNull = IDEEutilsModule.isNull;
IDEE.utils.isNullOrEmpty = IDEEutilsModule.isNullOrEmpty;
IDEE.utils.isObject = IDEEutilsModule.isObject;
IDEE.utils.isString = IDEEutilsModule.isString;
IDEE.utils.isUndefined = IDEEutilsModule.isUndefined;
IDEE.utils.isUrl = IDEEutilsModule.isUrl;
IDEE.utils.modifySVG = IDEEutilsModule.modifySVG;
IDEE.utils.normalize = IDEEutilsModule.normalize;
IDEE.utils.returnPositionHtmlElement = IDEEutilsModule.returnPositionHtmlElement;
IDEE.utils.rgbToHex = IDEEutilsModule.rgbToHex;
IDEE.utils.rgbaToHex = IDEEutilsModule.rgbaToHex;
IDEE.utils.sameUrl = IDEEutilsModule.sameUrl;
IDEE.utils.setDynamicLegend = IDEEutilsModule.setDynamicLegend;
IDEE.utils.stringToHtml = IDEEutilsModule.stringToHtml;
IDEE.utils.stringifyFunctions = IDEEutilsModule.stringifyFunctions;
IDEE.utils.styleComparator = IDEEutilsModule.styleComparator;
IDEE.utils.transfomContent = IDEEutilsModule.transfomContent;
IDEE.window = $IDEE$window;
ol.Collection = $ol$Collection;
ol.Feature = $ol$Feature;
ol.Geolocation = $ol$Geolocation;
ol.Image = {};
ol.Image.decode = olImageModule.decode;
ol.Image.load = olImageModule.load;
ol.Kinetic = $ol$Kinetic;
ol.Map = $ol$Map;
ol.Object = $ol$Object;
ol.Observable = $ol$Observable;
ol.Observable.unByKey = olObservableModule.unByKey;
ol.Overlay = $ol$Overlay;
ol.View = $ol$View;
ol.array = {};
ol.array.stableSort = olarrayModule.stableSort;
ol.color = {};
ol.color.asArray = olcolorModule.asArray;
ol.color.asString = olcolorModule.asString;
ol.colorlike = {};
ol.colorlike.asColorLike = olcolorlikeModule.asColorLike;
ol.control = {};
ol.control.Attribution = $ol$control$Attribution;
ol.control.Control = $ol$control$Control;
ol.control.FullScreen = $ol$control$FullScreen;
ol.control.MousePosition = $ol$control$MousePosition;
ol.control.OverviewMap = $ol$control$OverviewMap;
ol.control.Rotate = $ol$control$Rotate;
ol.control.ScaleLine = $ol$control$ScaleLine;
ol.control.Zoom = $ol$control$Zoom;
ol.control.ZoomSlider = $ol$control$ZoomSlider;
ol.control.ZoomToExtent = $ol$control$ZoomToExtent;
ol.control.defaults = {};
ol.control.defaults.defaults = olcontroldefaultsModule.defaults;
ol.coordinate = {};
ol.coordinate.add = olcoordinateModule.add;
ol.coordinate.createStringXY = olcoordinateModule.createStringXY;
ol.coordinate.format = olcoordinateModule.format;
ol.coordinate.rotate = olcoordinateModule.rotate;
ol.coordinate.toStringHDMS = olcoordinateModule.toStringHDMS;
ol.coordinate.toStringXY = olcoordinateModule.toStringXY;
ol.easing = {};
ol.easing.easeIn = oleasingModule.easeIn;
ol.easing.easeOut = oleasingModule.easeOut;
ol.easing.inAndOut = oleasingModule.inAndOut;
ol.easing.linear = oleasingModule.linear;
ol.easing.upAndDown = oleasingModule.upAndDown;
ol.events = {};
ol.events.condition = {};
ol.events.condition.altKeyOnly = oleventsconditionModule.altKeyOnly;
ol.events.condition.altShiftKeysOnly = oleventsconditionModule.altShiftKeysOnly;
ol.events.condition.always = oleventsconditionModule.always;
ol.events.condition.click = oleventsconditionModule.click;
ol.events.condition.doubleClick = oleventsconditionModule.doubleClick;
ol.events.condition.focus = oleventsconditionModule.focus;
ol.events.condition.mouseOnly = oleventsconditionModule.mouseOnly;
ol.events.condition.never = oleventsconditionModule.never;
ol.events.condition.noModifierKeys = oleventsconditionModule.noModifierKeys;
ol.events.condition.penOnly = oleventsconditionModule.penOnly;
ol.events.condition.platformModifierKey = oleventsconditionModule.platformModifierKey;
ol.events.condition.platformModifierKeyOnly = oleventsconditionModule.platformModifierKeyOnly;
ol.events.condition.pointerMove = oleventsconditionModule.pointerMove;
ol.events.condition.primaryAction = oleventsconditionModule.primaryAction;
ol.events.condition.shiftKeyOnly = oleventsconditionModule.shiftKeyOnly;
ol.events.condition.singleClick = oleventsconditionModule.singleClick;
ol.events.condition.targetNotEditable = oleventsconditionModule.targetNotEditable;
ol.events.condition.touchOnly = oleventsconditionModule.touchOnly;
ol.extent = {};
ol.extent.applyTransform = olextentModule.applyTransform;
ol.extent.boundingExtent = olextentModule.boundingExtent;
ol.extent.buffer = olextentModule.buffer;
ol.extent.containsCoordinate = olextentModule.containsCoordinate;
ol.extent.containsExtent = olextentModule.containsExtent;
ol.extent.containsXY = olextentModule.containsXY;
ol.extent.createEmpty = olextentModule.createEmpty;
ol.extent.equals = olextentModule.equals;
ol.extent.extend = olextentModule.extend;
ol.extent.getArea = olextentModule.getArea;
ol.extent.getBottomLeft = olextentModule.getBottomLeft;
ol.extent.getBottomRight = olextentModule.getBottomRight;
ol.extent.getCenter = olextentModule.getCenter;
ol.extent.getHeight = olextentModule.getHeight;
ol.extent.getIntersection = olextentModule.getIntersection;
ol.extent.getSize = olextentModule.getSize;
ol.extent.getTopLeft = olextentModule.getTopLeft;
ol.extent.getTopRight = olextentModule.getTopRight;
ol.extent.getWidth = olextentModule.getWidth;
ol.extent.intersects = olextentModule.intersects;
ol.extent.isEmpty = olextentModule.isEmpty;
ol.featureloader = {};
ol.featureloader.setWithCredentials = olfeatureloaderModule.setWithCredentials;
ol.featureloader.xhr = olfeatureloaderModule.xhr;
ol.format = {};
ol.format.EsriJSON = $ol$format$EsriJSON;
ol.format.Feature = $ol$format$Feature;
ol.format.GML = $ol$format$GML;
ol.format.GML2 = $ol$format$GML2;
ol.format.GML3 = $ol$format$GML3;
ol.format.GML32 = $ol$format$GML32;
ol.format.GMLBase = $ol$format$GMLBase;
ol.format.GPX = $ol$format$GPX;
ol.format.GeoJSON = $ol$format$GeoJSON;
ol.format.IGC = $ol$format$IGC;
ol.format.IIIFInfo = $ol$format$IIIFInfo;
ol.format.KML = $ol$format$KML;
ol.format.MVT = $ol$format$MVT;
ol.format.OSMXML = $ol$format$OSMXML;
ol.format.Polyline = $ol$format$Polyline;
ol.format.Polyline.decodeDeltas = olformatPolylineModule.decodeDeltas;
ol.format.Polyline.decodeFloats = olformatPolylineModule.decodeFloats;
ol.format.Polyline.encodeDeltas = olformatPolylineModule.encodeDeltas;
ol.format.Polyline.encodeFloats = olformatPolylineModule.encodeFloats;
ol.format.TopoJSON = $ol$format$TopoJSON;
ol.format.WFS = $ol$format$WFS;
ol.format.WFS.writeFilter = olformatWFSModule.writeFilter;
ol.format.WKB = $ol$format$WKB;
ol.format.WKT = $ol$format$WKT;
ol.format.WMSCapabilities = $ol$format$WMSCapabilities;
ol.format.WMSGetFeatureInfo = $ol$format$WMSGetFeatureInfo;
ol.format.WMTSCapabilities = $ol$format$WMTSCapabilities;
ol.format.filter = {};
ol.format.filter.Bbox = $ol$format$filter$Bbox;
ol.format.filter.Contains = $ol$format$filter$Contains;
ol.format.filter.DWithin = $ol$format$filter$DWithin;
ol.format.filter.Disjoint = $ol$format$filter$Disjoint;
ol.format.filter.During = $ol$format$filter$During;
ol.format.filter.EqualTo = $ol$format$filter$EqualTo;
ol.format.filter.GreaterThan = $ol$format$filter$GreaterThan;
ol.format.filter.GreaterThanOrEqualTo = $ol$format$filter$GreaterThanOrEqualTo;
ol.format.filter.Intersects = $ol$format$filter$Intersects;
ol.format.filter.IsBetween = $ol$format$filter$IsBetween;
ol.format.filter.IsLike = $ol$format$filter$IsLike;
ol.format.filter.IsNull = $ol$format$filter$IsNull;
ol.format.filter.LessThan = $ol$format$filter$LessThan;
ol.format.filter.LessThanOrEqualTo = $ol$format$filter$LessThanOrEqualTo;
ol.format.filter.Not = $ol$format$filter$Not;
ol.format.filter.NotEqualTo = $ol$format$filter$NotEqualTo;
ol.format.filter.Or = $ol$format$filter$Or;
ol.format.filter.Within = $ol$format$filter$Within;
ol.format.filter.and = olformatfilterModule.and;
ol.format.filter.bbox = olformatfilterModule.bbox;
ol.format.filter.between = olformatfilterModule.between;
ol.format.filter.contains = olformatfilterModule.contains;
ol.format.filter.disjoint = olformatfilterModule.disjoint;
ol.format.filter.during = olformatfilterModule.during;
ol.format.filter.dwithin = olformatfilterModule.dwithin;
ol.format.filter.equalTo = olformatfilterModule.equalTo;
ol.format.filter.greaterThan = olformatfilterModule.greaterThan;
ol.format.filter.greaterThanOrEqualTo = olformatfilterModule.greaterThanOrEqualTo;
ol.format.filter.intersects = olformatfilterModule.intersects;
ol.format.filter.isNull = olformatfilterModule.isNull;
ol.format.filter.lessThan = olformatfilterModule.lessThan;
ol.format.filter.lessThanOrEqualTo = olformatfilterModule.lessThanOrEqualTo;
ol.format.filter.like = olformatfilterModule.like;
ol.format.filter.not = olformatfilterModule.not;
ol.format.filter.notEqualTo = olformatfilterModule.notEqualTo;
ol.format.filter.or = olformatfilterModule.or;
ol.format.filter.within = olformatfilterModule.within;
ol.geom = {};
ol.geom.Circle = $ol$geom$Circle;
ol.geom.Geometry = $ol$geom$Geometry;
ol.geom.GeometryCollection = $ol$geom$GeometryCollection;
ol.geom.LineString = $ol$geom$LineString;
ol.geom.LinearRing = $ol$geom$LinearRing;
ol.geom.MultiLineString = $ol$geom$MultiLineString;
ol.geom.MultiPoint = $ol$geom$MultiPoint;
ol.geom.MultiPolygon = $ol$geom$MultiPolygon;
ol.geom.Point = $ol$geom$Point;
ol.geom.Polygon = $ol$geom$Polygon;
ol.geom.Polygon.circular = olgeomPolygonModule.circular;
ol.geom.Polygon.fromCircle = olgeomPolygonModule.fromCircle;
ol.geom.Polygon.fromExtent = olgeomPolygonModule.fromExtent;
ol.geom.SimpleGeometry = $ol$geom$SimpleGeometry;
ol.has = {};
ol.has.DEVICE_PIXEL_RATIO = olhasModule.DEVICE_PIXEL_RATIO;
ol.interaction = {};
ol.interaction.DblClickDragZoom = $ol$interaction$DblClickDragZoom;
ol.interaction.DoubleClickZoom = $ol$interaction$DoubleClickZoom;
ol.interaction.DragAndDrop = $ol$interaction$DragAndDrop;
ol.interaction.DragBox = $ol$interaction$DragBox;
ol.interaction.DragPan = $ol$interaction$DragPan;
ol.interaction.DragRotate = $ol$interaction$DragRotate;
ol.interaction.DragRotateAndZoom = $ol$interaction$DragRotateAndZoom;
ol.interaction.DragZoom = $ol$interaction$DragZoom;
ol.interaction.Draw = $ol$interaction$Draw;
ol.interaction.Draw.createBox = olinteractionDrawModule.createBox;
ol.interaction.Draw.createRegularPolygon = olinteractionDrawModule.createRegularPolygon;
ol.interaction.Extent = $ol$interaction$Extent;
ol.interaction.Interaction = $ol$interaction$Interaction;
ol.interaction.KeyboardPan = $ol$interaction$KeyboardPan;
ol.interaction.KeyboardZoom = $ol$interaction$KeyboardZoom;
ol.interaction.Link = $ol$interaction$Link;
ol.interaction.Modify = $ol$interaction$Modify;
ol.interaction.MouseWheelZoom = $ol$interaction$MouseWheelZoom;
ol.interaction.PinchRotate = $ol$interaction$PinchRotate;
ol.interaction.PinchZoom = $ol$interaction$PinchZoom;
ol.interaction.Pointer = $ol$interaction$Pointer;
ol.interaction.Select = $ol$interaction$Select;
ol.interaction.Snap = $ol$interaction$Snap;
ol.interaction.Translate = $ol$interaction$Translate;
ol.interaction.defaults = {};
ol.interaction.defaults.defaults = olinteractiondefaultsModule.defaults;
ol.layer = {};
ol.layer.Base = $ol$layer$Base;
ol.layer.BaseImage = $ol$layer$BaseImage;
ol.layer.BaseTile = $ol$layer$BaseTile;
ol.layer.BaseVector = $ol$layer$BaseVector;
ol.layer.Graticule = $ol$layer$Graticule;
ol.layer.Group = $ol$layer$Group;
ol.layer.Heatmap = $ol$layer$Heatmap;
ol.layer.Image = $ol$layer$Image;
ol.layer.Layer = $ol$layer$Layer;
ol.layer.Tile = $ol$layer$Tile;
ol.layer.Vector = $ol$layer$Vector;
ol.layer.VectorImage = $ol$layer$VectorImage;
ol.layer.VectorTile = $ol$layer$VectorTile;
ol.layer.WebGLTile = $ol$layer$WebGLTile;
ol.loadingstrategy = {};
ol.loadingstrategy.all = olloadingstrategyModule.all;
ol.loadingstrategy.bbox = olloadingstrategyModule.bbox;
ol.loadingstrategy.tile = olloadingstrategyModule.tile;
ol.proj = {};
ol.proj.Projection = $ol$proj$Projection;
ol.proj.Units = {};
ol.proj.Units.METERS_PER_UNIT = olprojUnitsModule.METERS_PER_UNIT;
ol.proj.addCoordinateTransforms = olprojModule.addCoordinateTransforms;
ol.proj.addEquivalentProjections = olprojModule.addEquivalentProjections;
ol.proj.addProjection = olprojModule.addProjection;
ol.proj.clearUserProjection = olprojModule.clearUserProjection;
ol.proj.equivalent = olprojModule.equivalent;
ol.proj.fromLonLat = olprojModule.fromLonLat;
ol.proj.get = olprojModule.get;
ol.proj.getPointResolution = olprojModule.getPointResolution;
ol.proj.getTransform = olprojModule.getTransform;
ol.proj.getUserProjection = olprojModule.getUserProjection;
ol.proj.proj4 = {};
ol.proj.proj4.epsgLookupMapTiler = olprojproj4Module.epsgLookupMapTiler;
ol.proj.proj4.fromEPSGCode = olprojproj4Module.fromEPSGCode;
ol.proj.proj4.register = olprojproj4Module.register;
ol.proj.proj4.setEPSGLookup = olprojproj4Module.setEPSGLookup;
ol.proj.setUserProjection = olprojModule.setUserProjection;
ol.proj.toLonLat = olprojModule.toLonLat;
ol.proj.transform = olprojModule.transform;
ol.proj.transformExtent = olprojModule.transformExtent;
ol.proj.useGeographic = olprojModule.useGeographic;
ol.render = {};
ol.render.Feature = {};
ol.render.Feature.toFeature = olrenderFeatureModule.toFeature;
ol.render.Feature.toGeometry = olrenderFeatureModule.toGeometry;
ol.render.VectorContext = $ol$render$VectorContext;
ol.render.getRenderPixel = olrenderModule.getRenderPixel;
ol.render.getVectorContext = olrenderModule.getVectorContext;
ol.render.toContext = olrenderModule.toContext;
ol.renderer = {};
ol.renderer.Composite = $ol$renderer$Composite;
ol.renderer.canvas = {};
ol.renderer.canvas.ImageLayer = $ol$renderer$canvas$ImageLayer;
ol.renderer.canvas.TileLayer = $ol$renderer$canvas$TileLayer;
ol.renderer.canvas.VectorImageLayer = $ol$renderer$canvas$VectorImageLayer;
ol.renderer.canvas.VectorLayer = $ol$renderer$canvas$VectorLayer;
ol.renderer.canvas.VectorTileLayer = $ol$renderer$canvas$VectorTileLayer;
ol.renderer.webgl = {};
ol.renderer.webgl.PointsLayer = $ol$renderer$webgl$PointsLayer;
ol.renderer.webgl.TileLayer = $ol$renderer$webgl$TileLayer;
ol.size = {};
ol.size.toSize = olsizeModule.toSize;
ol.source = {};
ol.source.BingMaps = $ol$source$BingMaps;
ol.source.CartoDB = $ol$source$CartoDB;
ol.source.Cluster = $ol$source$Cluster;
ol.source.DataTile = $ol$source$DataTile;
ol.source.GeoTIFF = $ol$source$GeoTIFF;
ol.source.IIIF = $ol$source$IIIF;
ol.source.Image = $ol$source$Image;
ol.source.ImageArcGISRest = $ol$source$ImageArcGISRest;
ol.source.ImageCanvas = $ol$source$ImageCanvas;
ol.source.ImageMapGuide = $ol$source$ImageMapGuide;
ol.source.ImageStatic = $ol$source$ImageStatic;
ol.source.ImageWMS = $ol$source$ImageWMS;
ol.source.OGCMapTile = $ol$source$OGCMapTile;
ol.source.OGCVectorTile = $ol$source$OGCVectorTile;
ol.source.OSM = $ol$source$OSM;
ol.source.OSM.ATTRIBUTION = olsourceOSMModule.ATTRIBUTION;
ol.source.Raster = $ol$source$Raster;
ol.source.Source = $ol$source$Source;
ol.source.StadiaMaps = $ol$source$StadiaMaps;
ol.source.Tile = $ol$source$Tile;
ol.source.TileArcGISRest = $ol$source$TileArcGISRest;
ol.source.TileDebug = $ol$source$TileDebug;
ol.source.TileImage = $ol$source$TileImage;
ol.source.TileJSON = $ol$source$TileJSON;
ol.source.TileWMS = $ol$source$TileWMS;
ol.source.UTFGrid = $ol$source$UTFGrid;
ol.source.Vector = $ol$source$Vector;
ol.source.VectorTile = $ol$source$VectorTile;
ol.source.WMTS = $ol$source$WMTS;
ol.source.WMTS.optionsFromCapabilities = olsourceWMTSModule.optionsFromCapabilities;
ol.source.XYZ = $ol$source$XYZ;
ol.source.Zoomify = $ol$source$Zoomify;
ol.source.arcgisRest = {};
ol.source.arcgisRest.createLoader = olsourcearcgisRestModule.createLoader;
ol.source.mapguide = {};
ol.source.mapguide.createLoader = olsourcemapguideModule.createLoader;
ol.source.sourcesFromTileGrid = olsourceModule.sourcesFromTileGrid;
ol.source.static = {};
ol.source.static.createLoader = olsourcestaticModule.createLoader;
ol.source.wms = {};
ol.source.wms.createLoader = olsourcewmsModule.createLoader;
ol.sphere = {};
ol.sphere.getArea = olsphereModule.getArea;
ol.sphere.getDistance = olsphereModule.getDistance;
ol.sphere.getLength = olsphereModule.getLength;
ol.style = {};
ol.style.Circle = $ol$style$Circle;
ol.style.Fill = $ol$style$Fill;
ol.style.Icon = $ol$style$Icon;
ol.style.IconImageCache = {};
ol.style.IconImageCache.shared = olstyleIconImageCacheModule.shared;
ol.style.Image = $ol$style$Image;
ol.style.RegularShape = $ol$style$RegularShape;
ol.style.Stroke = $ol$style$Stroke;
ol.style.Style = $ol$style$Style;
ol.style.Text = $ol$style$Text;
ol.tilegrid = {};
ol.tilegrid.TileGrid = $ol$tilegrid$TileGrid;
ol.tilegrid.WMTS = $ol$tilegrid$WMTS;
ol.tilegrid.WMTS.createFromCapabilitiesMatrixSet = oltilegridWMTSModule.createFromCapabilitiesMatrixSet;
ol.tilegrid.createXYZ = oltilegridModule.createXYZ;
ol.transform = {};
ol.transform.composeCssTransform = oltransformModule.composeCssTransform;
ol.util = {};
ol.util.getUid = olutilModule.getUid;
ol.webgl = {};
ol.webgl.ARRAY_BUFFER = olwebglModule.ARRAY_BUFFER;
ol.webgl.DYNAMIC_DRAW = olwebglModule.DYNAMIC_DRAW;
ol.webgl.ELEMENT_ARRAY_BUFFER = olwebglModule.ELEMENT_ARRAY_BUFFER;
ol.webgl.STATIC_DRAW = olwebglModule.STATIC_DRAW;
ol.webgl.STREAM_DRAW = olwebglModule.STREAM_DRAW;
ol.xml = {};
ol.xml.getAllTextContent = olxmlModule.getAllTextContent;
ol.xml.parse = olxmlModule.parse;
ol.xml.registerDocument = olxmlModule.registerDocument;
ol.xml.registerXMLSerializer = olxmlModule.registerXMLSerializer;
window['Handlebars'] = require('handlebars');
window['proj4'] = require('proj4');
IDEE.plugin = {}